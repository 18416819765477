<template>
  <VListItem v-bind="itemProps" :title="undefined" density="compact">
    <VRow>
      <!-- eslint-disable vue/no-v-html -->
      <VCol class="overflow-hidden text-body-1 text-truncate">
        <span v-html="highlightedId" />
      </VCol>
      <VCol class="overflow-hidden text-body-1 text-truncate">
        <span v-html="highlightedName" />
      </VCol>
      <VCol class="overflow-hidden text-body-1 text-truncate">
        <span v-html="highlightedDateOfBirth" />
      </VCol>
      <VCol class="overflow-hidden text-body-1 text-truncate">
        <span v-html="highlightedHospital" />
      </VCol>
      <VCol class="overflow-hidden text-body-1 text-truncate">
        <span v-html="highlightedWard" />
      </VCol>
      <!--eslint-enable-->
    </VRow>
  </VListItem>
</template>

<script setup lang="ts">
import type { SearchItemDisplayData } from '@/components/navigation/patientsearch/SearchItem'
import { computed } from 'vue'

const props = defineProps<{
  itemProps: Record<string, unknown>
  displayData: SearchItemDisplayData
  searchInput: string
}>()

const highlightedId = computed<string>(() => highlightText(props.displayData.id, props.searchInput))

const highlightedName = computed<string>(() =>
  highlightText(props.displayData.name, props.searchInput)
)

const highlightedDateOfBirth = computed<string>(() =>
  highlightText(props.displayData.dateOfBirth, props.searchInput)
)

const highlightedHospital = computed<string>(() =>
  highlightText(props.displayData.hospital, props.searchInput)
)

const highlightedWard = computed<string>(() =>
  highlightText(props.displayData.ward, props.searchInput)
)

function highlightText(text: string, textToHighlight: string): string {
  if (!textToHighlight) return text

  const regex = new RegExp(`${textToHighlight}`, 'ig')
  return text.replace(regex, (match) => `<span class="font-weight-bold">${match}</span>`)
}
</script>
