<template>
  <VApp>
    <VAppBar v-if="hasSelectedShift" app elevation="0" class="border-b-sm">
      <MenuBar v-model="isMobileMenuDrawerOpened" />
    </VAppBar>

    <VMain class="full-height">
      <ConsultationBar v-if="hasSelectedShift" />
      <VContainer v-if="hasSelectedShift" fluid class="max-width-1200 pt-0 p-0 sm:p-4">
        <CallFrame v-if="!isJoinConsultationPath" />
        <RouterView />
      </VContainer>
      <ShiftSelectionOverlay v-else />
    </VMain>

    <MobileMenuDrawer v-model="isMobileMenuDrawerOpened" />

    <SnackbarQueue class="pb-6" />

    <VFooter color="background" class="p-4 pl-6 pr-6">
      <PageFooter />
    </VFooter>
  </VApp>
</template>

<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user'
import ShiftSelectionOverlay from '@/components/shift/ShiftSelectionOverlay.vue'
import { storeToRefs } from 'pinia'
import MenuBar from '@/components/navigation/MenuBar.vue'
import SnackbarQueue from '@/components/notices/SnackbarQueue.vue'
import PageFooter from '@/components/page/PageFooter.vue'
import CallFrame from '@/components/CallFrame.vue'
import ConsultationBar from '@/components/consultationrequest/ConsultationBar.vue'
import { computed, ref } from 'vue'
import MobileMenuDrawer from '@/components/navigation/MobileMenuDrawer.vue'

const route = useRoute()

const isJoinConsultationPath = computed(() => route.name === 'join-consultation')

const { hasSelectedShift } = storeToRefs(useUserStore())
const isMobileMenuDrawerOpened = ref<boolean>(false)
</script>

<style>
html {
  overflow-y: auto !important;
}

.max-width-1200 {
  max-width: 1200px;
}

.full-height {
  /* 68 px is the footer height */
  height: -webkit-calc(100% - 68px);
  height: calc(100% - 68px);
}
</style>
