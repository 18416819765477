<template>
  <VCardTitle class="flex justify-space-between items-center p-0">
    <div class="text-h4 p-4 pb-2">{{ t('Filter patients') }}</div>
    <VBtn
      icon="close"
      variant="plain"
      class="button-small mr-2"
      size="small"
      :ripple="false"
      data-testid="btn-close"
      @click="emits('close-dialog')"
    />
  </VCardTitle>
  <VCardItem class="p-4 pb-0">
    <span class="text-subtitle-1">
      {{ t('Hospital') }}
    </span>
    <VAutocomplete
      v-model="caseStore.patientFilterInput.hospitals"
      clear-icon="custom:clear20"
      :items="hospitalData"
      chips
      closable-chips
      multiple
    />
  </VCardItem>
  <div class="overflow-y-auto">
    <VCardItem v-if="departmentsWithPatientCount.length > 0" class="p-2">
      <PatientFilterOptionGroup
        v-model="patientFilterInput.departments"
        :title="t('Departments (optional)')"
        :items="departmentsWithPatientCount"
        data-testid="checkboxes-departments"
      />
    </VCardItem>
    <VCardItem v-if="wardsWithPatientCount.length > 0" class="p-2">
      <PatientFilterOptionGroup
        v-model="patientFilterInput.wards"
        :title="t('Wards (optional)')"
        :items="wardsWithPatientCount"
        data-testid="checkboxes-wards"
      />
    </VCardItem>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useCaseStore } from '@/stores/case'
import { computed, onMounted } from 'vue'
import type { SelectOption } from '@/util/SelectOption'
import type { CaseDTO, HospitalDTO } from '@/generated'
import type { FilterOption } from '@/components/patient/models/FilterOption'
import PatientFilterOptionGroup from '@/components/patient/filter/PatientFilterOptionGroup.vue'

const emits = defineEmits(['close-dialog'])

const { t } = useI18n()
const caseStore = useCaseStore()
const { caseList, patientFilterInput } = storeToRefs(caseStore)

onMounted(() => {
  caseStore.resetPatientFilterInput()
})

const hospitalData = computed<SelectOption<HospitalDTO>[]>(() => {
  const uniqueHospitals: HospitalDTO[] = [
    ...new Map(
      caseList.value
        .map((caze: CaseDTO) => caze.hospital)
        .map((hospital) => [hospital.id, hospital])
    ).values()
  ]

  return uniqueHospitals.map((hospital: HospitalDTO) => {
    return {
      value: hospital,
      title: hospital.name
    }
  })
})

const wardsWithPatientCount = computed<FilterOption[]>(() => {
  const uniqueWardNames = [...new Set(caseList.value.map((caze) => caze.ward.name))]

  return uniqueWardNames.map((wardName) => {
    return { name: wardName, count: getPatientCountForWardName(wardName) }
  })
})

const departmentsWithPatientCount = computed<FilterOption[]>(() => {
  const uniqueDepartmentNames = [...new Set(caseList.value.map((caze) => caze.department.name))]

  return uniqueDepartmentNames.map((departmentName) => {
    return {
      name: departmentName,
      count: getPatientCountForDepartmentName(departmentName)
    }
  })
})

function getPatientCountForWardName(wardName: string): number {
  return caseList.value.filter(
    (caze: CaseDTO) =>
      caseStore.patientFilterInput.hospitals
        .map((hospital: HospitalDTO) => hospital.id)
        .includes(caze.hospital.id) && caze.ward.name === wardName
  ).length
}

function getPatientCountForDepartmentName(departmentName: string): number {
  return caseList.value.filter(
    (caze: CaseDTO) =>
      caseStore.patientFilterInput.hospitals
        .map((hospital: HospitalDTO) => hospital.id)
        .includes(caze.hospital.id) && caze.department.name === departmentName
  ).length
}
</script>
