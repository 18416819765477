import type { ThemeDefinition } from 'vuetify'

export const lightTheme: ThemeDefinition = {
  colors: {
    primary: '#206683',
    'primary-light': '#6A98AC',
    'primary-dark': '#17485d',
    'primary-opacity-8': '#edf3f5',
    secondary: '#ec6661',
    'secondary-light': '#f29895',
    'secondary-dark': '#a84845',
    error: '#ec6661',
    'error-light': '#f29895',
    'error-dark': '#a84845',
    success: '#4CAF50',
    'success-light': '#7BC67E',
    'success-dark': '#3B873E',
    'success-badge': '#27ab6e',
    warning: '#FF9800',
    'warning-light': '#FFB547',
    'warning-dark': '#C77700',
    'warning-background': '#FDF4E7',
    background: '#FAFAFA',
    surface: '#FFFFFF',
    'primary-text': '#0D2B37',
    'secondary-text': '#667085',
    'on-background': '#0D2B37',
    'on-surface': '#0D2B37',
    disabled: '#506176',
    'action-active': '#98A2B3',
    'grey-50': '#FAFAFA',
    'grey-100': '#F5F5F5',
    'grey-200': '#EEEEEE',
    'grey-300': '#E0E0E0',
    'grey-400': '#BDBDBD',
    'grey-500': '#9E9E9E',
    'grey-600': '#757575',
    'grey-700': '#616161',
    'grey-800': '#424242',
    'grey-900': '#212121',
    'mobile-menu-icon': '#1E2134'
  },
  variables: {
    'medium-emphasis-opacity': 1,
    'high-emphasis-opacity': 1,
    'snackbar-content-padding': '6px 16px',
    'shadow-key-umbra-opacity': 'rgba(29, 41, 57, 0.08)',
    'shadow-key-penumbra-opacity': 'rgba(29, 41, 57, 0.08)',
    'shadow-key-ambient-opacity': 'rgba(29, 41, 57, 0.08)'
  }
}
