/* c8 ignore file */
/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { ButtonVariant } from '@/components/consultationrequest/ButtonVariant'
import { MedicalFieldsDTO } from '@/components/patient/models/MedicalFieldsDTO'
import {
  ConsultationRequestStatusDTO,
  FileKindEnum,
  GenderDTO,
  ShiftTypeDTO,
  UrgencyDTO
} from '@/generated'

export const translateConsultationRequestStatusDTO = (
  status: ConsultationRequestStatusDTO | string,
  t: Function
): string => {
  switch (status) {
    case ConsultationRequestStatusDTO.CREATED:
      return t('Created')
    case ConsultationRequestStatusDTO.ACCEPTED:
      return t('Accepted')
    case ConsultationRequestStatusDTO.IN_PROGRESS:
      return t('In Progress')
    case ConsultationRequestStatusDTO.REPORT_PENDING:
      return t('Report Pending')
    case ConsultationRequestStatusDTO.COMPLETED:
      return t('Completed')
    case ConsultationRequestStatusDTO.DELETED:
      return t('Deleted')
    default:
      return status
  }
}

export const translateMedicalFieldsDTO = (
  field: MedicalFieldsDTO | string,
  t: Function
): string => {
  switch (field) {
    case MedicalFieldsDTO.ANESTHESIOLOGY:
      return t('Anesthesiology')
    case MedicalFieldsDTO.OCCUPATIONAL_MEDICINE:
      return t('Occupational Medicine')
    case MedicalFieldsDTO.OPHTHALMOLOGY:
      return t('Ophthalmology')
    case MedicalFieldsDTO.GENERAL_SURGERY:
      return t('General Surgery')
    case MedicalFieldsDTO.VASCULAR_SURGERY:
      return t('Vascular Surgery')
    case MedicalFieldsDTO.CARDIAC_SURGERY_CARDIOTHORACIC_SURGERY:
      return t('Cardiac Surgery (Cardiothoracic Surgery)')
    case MedicalFieldsDTO.PEDIATRIC_SURGERY:
      return t('Pediatric Surgery')
    case MedicalFieldsDTO.ORTHOPEDICS_AND_TRAUMA_SURGERY:
      return t('Orthopedics and Trauma Surgery')
    case MedicalFieldsDTO.THORACIC_SURGERY:
      return t('Thoracic Surgery')
    case MedicalFieldsDTO.VISCERAL_SURGERY:
      return t('Visceral Surgery')
    case MedicalFieldsDTO.GYNECOLOGY:
      return t('Gynecology')
    case MedicalFieldsDTO.DERMATOLOGY:
      return t('Dermatology')
    case MedicalFieldsDTO.INTERNAL_MEDICINE:
      return t('Internal Medicine')
    case MedicalFieldsDTO.INTERNAL_MEDICINE_AND_ANGIOLOGY:
      return t('Internal Medicine and Angiology')
    case MedicalFieldsDTO.INTERNAL_MEDICINE_AND_ENDOCRINOLOGY_AND_DIABETOLOGY:
      return t('Internal Medicine and Endocrinology and Diabetology')
    case MedicalFieldsDTO.INTERNAL_MEDICINE_AND_GASTROENTEROLOGY:
      return t('Internal Medicine and Gastroenterology')
    case MedicalFieldsDTO.INTERNAL_MEDICINE_AND_HEMATOLOGY_AND_ONCOLOGY:
      return t('Internal Medicine and Hematology and Oncology')
    case MedicalFieldsDTO.INTERNAL_MEDICINE_AND_CARDIOLOGY:
      return t('Internal Medicine and Cardiology')
    case MedicalFieldsDTO.INTERNAL_MEDICINE_AND_NEPHROLOGY:
      return t('Internal Medicine and Nephrology')
    case MedicalFieldsDTO.INTERNAL_MEDICINE_AND_PULMONOLOGY:
      return t('Internal Medicine and Pulmonology')
    case MedicalFieldsDTO.INTERNAL_MEDICINE_AND_RHEUMATOLOGY:
      return t('Internal Medicine and Rheumatology')
    case MedicalFieldsDTO.PEDIATRICS:
      return t('Pediatrics')
    case MedicalFieldsDTO.MICROBIOLOGY_VIROLOGY_AND_INFECTION_EPIDEMIOLOGY:
      return t('Microbiology, Virology, and Infection Epidemiology')
    case MedicalFieldsDTO.ORAL_AND_MAXILLOFACIAL_SURGERY:
      return t('Oral and Maxillofacial Surgery')
    case MedicalFieldsDTO.NEUROSURGERY:
      return t('Neurosurgery')
    case MedicalFieldsDTO.NEUROLOGY:
      return t('Neurology')
    case MedicalFieldsDTO.PHARMACOLOGY_AND_TOXICOLOGY:
      return t('Pharmacology and Toxicology')
    case MedicalFieldsDTO.PSYCHIATRY_AND_PSYCHOTHERAPY:
      return t('Psychiatry and Psychotherapy')
    case MedicalFieldsDTO.NEURORADIOLOGY:
      return t('Neuroradiology')
    case MedicalFieldsDTO.RADIATION_THERAPY:
      return t('Radiation Therapy')
    case MedicalFieldsDTO.UROLOGY:
      return t('Urology')
    case MedicalFieldsDTO.DIABETOLOGY:
      return t('Diabetology')
    case MedicalFieldsDTO.NUTRITIONAL_MEDICINE:
      return t('Nutritional Medicine')
    case MedicalFieldsDTO.GERIATRICS:
      return t('Geriatrics')
    case MedicalFieldsDTO.IMMUNOLOGY:
      return t('Immunology')
    case MedicalFieldsDTO.INFECTIOLOGY:
      return t('Infectiology')
    case MedicalFieldsDTO.INTENSIVE_CARE_MEDICINE:
      return t('Intensive Care Medicine')
    case MedicalFieldsDTO.PALLIATIVE_MEDICINE:
      return t('Palliative Medicine')
    case MedicalFieldsDTO.SPECIALIZED_PAIN_THERAPY:
      return t('Specialized Pain Therapy')
    default:
      return field
  }
}

export const translateShiftTypeDTO = (shiftType: ShiftTypeDTO | string, t: Function): string => {
  switch (shiftType) {
    case ShiftTypeDTO.FIRST_SHIFT:
      return t('First shift')
    case ShiftTypeDTO.BACKUP_SHIFT:
      return t('Backup shift')
    case ShiftTypeDTO.NO_SHIFT:
      return t('No shift')
    case ShiftTypeDTO.GOD_MODE:
      return t('Routing and Patient Viewer')
    case ShiftTypeDTO.CHIEF_PHYSICIAN:
      return t('Routing and Patient Supervisor')
    default:
      return shiftType
  }
}

export const translateFileKindEnum = (fileKind: FileKindEnum | string, t: Function): string => {
  switch (fileKind) {
    case FileKindEnum.MEDICAL_REPORT:
      return t('Medical reports')
    case FileKindEnum.CONSULTATION_REPORT:
      return t('Consultation reports')
    case FileKindEnum.DOCUMENTS:
      return t('Documents')
    default:
      return fileKind
  }
}

export const translateUrgencyDTO = (urgency: UrgencyDTO | string, t: Function): string => {
  switch (urgency) {
    case UrgencyDTO.EMERGENCY:
      return t('Emergency')
    case UrgencyDTO.URGENT:
      return t('Urgent')
    case UrgencyDTO.ROUTINE:
      return t('Routine')
    case UrgencyDTO.FOLLOW_UP:
      return t('Follow Up')
    default:
      return urgency
  }
}

export const translateGenderDTO = (gender: GenderDTO | string, t: Function): string => {
  switch (gender) {
    case GenderDTO.MALE:
      return t('m')
    case GenderDTO.FEMALE:
      return t('f')
    case GenderDTO.DIVERSE:
      return t('d')
    case GenderDTO.OTHER:
      return t('other')
    default:
      return gender
  }
}

export const translateButtonVariant = (variant: ButtonVariant | string, t: Function): string => {
  switch (variant) {
    case ButtonVariant.ANSWER:
      return t('Answer')
    case ButtonVariant.CALL_BACK:
      return t('Call back now')
    case ButtonVariant.POST_CALL:
      return t('Call back')
    default:
      return variant
  }
}
