<template>
  <div class="min-w-[700px]">
    <div
      class="grid grid-cols-5 gap-4 text-body-1 !text-xs text-secondary-text bg-grey-100 py-2 px-6 mt-2"
    >
      <span
        v-if="patientDataSettingsStore.patientDataSettings.organSupport.vasopressors"
        class="place-self-center"
        >{{ t('Vasopressors') }}</span
      >
      <span
        v-if="patientDataSettingsStore.patientDataSettings.organSupport.ventilation"
        class="place-self-center"
        >{{ t('Ventilation') }}</span
      >
      <span
        v-if="patientDataSettingsStore.patientDataSettings.organSupport.dialysis"
        class="place-self-center"
        >{{ t('Dialysis') }}</span
      >
      <span
        v-if="patientDataSettingsStore.patientDataSettings.organSupport.ecmo"
        class="place-self-center"
        >{{ t('ECMO') }}</span
      >
      <span
        v-if="patientDataSettingsStore.patientDataSettings.organSupport.impella"
        class="place-self-center"
        >{{ t('Impella') }}</span
      >
    </div>
    <div class="grid grid-cols-5 gap-4 text-sm text-secondary-text py-2 px-6">
      <span
        v-if="patientDataSettingsStore.patientDataSettings.organSupport.vasopressors"
        :class="[
          'px-2 py-1 w-fit rounded place-self-center flex gap-1',
          { 'opacity-35': !props.values.vasopressors }
        ]"
      >
        <img src="/vasopressor.svg" alt="Vasopressors" class="place-self-center w-4 h-4" />
        {{ props.values.vasopressors ? t('Yes') : t('No') }}</span
      >
      <span
        v-if="patientDataSettingsStore.patientDataSettings.organSupport.ventilation"
        :class="[
          'px-2 py-1 w-fit rounded place-self-center flex gap-1',
          { 'opacity-35': !props.values.ventilation }
        ]"
      >
        <img src="/lungs.svg" alt="Vasopressors" class="place-self-center w-4 h-4" />
        {{ props.values.ventilation ? t('Yes') : t('No') }}</span
      >
      <span
        v-if="patientDataSettingsStore.patientDataSettings.organSupport.dialysis"
        :class="[
          'px-2 py-1 w-fit rounded place-self-center flex gap-1',
          { 'opacity-35': !props.values.dialysis }
        ]"
      >
        <img src="/dewpoint.svg" alt="Vasopressors" class="place-self-center w-4 h-4" />
        {{ props.values.dialysis ? t('Yes') : t('No') }}</span
      >
      <span
        v-if="patientDataSettingsStore.patientDataSettings.organSupport.ecmo"
        :class="[
          'px-2 py-1 w-fit rounded place-self-center flex gap-1',
          { 'opacity-35': !props.values.ecmo }
        ]"
      >
        <img src="/lungs.svg" alt="Vasopressors" class="place-self-center w-4 h-4" />
        {{ props.values.ecmo ? t('Yes') : t('No') }}</span
      >
      <span
        v-if="patientDataSettingsStore.patientDataSettings.organSupport.impella"
        :class="[
          'px-2 py-1 w-fit rounded place-self-center flex gap-1',
          { 'opacity-35': !props.values.impella }
        ]"
      >
        <img src="/heart.svg" alt="Vasopressors" class="place-self-center w-4 h-4" />
        {{ props.values.impella ? t('Yes') : t('No') }}</span
      >
    </div>
    <DividerLine class="mb-4" />
  </div>
</template>

<script setup lang="ts">
import DividerLine from '@/atoms/DividerLine.vue'
import { usePatientDataSettingsStore } from '@/stores/patientDataSettings'
import { useI18n } from 'vue-i18n'

const patientDataSettingsStore = usePatientDataSettingsStore()
const { t } = useI18n()

const props = defineProps<{
  values: {
    vasopressors: boolean
    ventilation: boolean
    dialysis: boolean
    ecmo: boolean
    impella: boolean
  }
}>()
</script>
