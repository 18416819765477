import {
  type CallAccessDTO,
  ConsultationRequestApi,
  type GetConsultationRequestShareCodeResponse
} from '@/generated'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'

export class CallService {
  static readonly consultationRequestApi = new ConsultationRequestApi(globalApiConfig)

  static async joinCall(consultationRequestId: string): Promise<CallAccessDTO> {
    return (await CallService.consultationRequestApi.joinCall(consultationRequestId)).data
  }

  static async joinWithConsultationShareCode(
    consultationShareCode: string
  ): Promise<CallAccessDTO> {
    return (
      await CallService.consultationRequestApi.joinConsultationRequestWithShareCode(
        consultationShareCode
      )
    ).data
  }

  static async getConsultationShareCode(
    consultationRequestId: string
  ): Promise<GetConsultationRequestShareCodeResponse> {
    return (
      await CallService.consultationRequestApi.getConsultationRequestShareCode(
        consultationRequestId
      )
    ).data
  }

  static async endCall(consultationRequestId: string): Promise<void> {
    await CallService.consultationRequestApi.endCall(consultationRequestId)
  }
}
