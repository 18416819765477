<template>
  <VCard v-if="roomId && !isCallFound" class="mt-4 p-4 flex justify-center">
    <VProgressCircular indeterminate color="primary" />
  </VCard>
  <VCard v-if="showCard && callFrameSrc" class="mt-4">
    <iframe :src="callFrameSrc" allow="camera *; microphone *; display-capture *" />

    <VCard
      v-if="!isJoinConsultationPath && consultationShareCode"
      class="flex flex-col sm:flex-row gap-2 items-start sm:items-center flex-wrap justify-between flex-1 mt-2 p-4"
    >
      <div class="flex flex-col sm:flex-row gap-2 items-start sm:items-center flex-1">
        <p class="text-h6">{{ t('Invite more participants:') }}</p>
        <div class="flex items-center gap-2 border border-gray-300 rounded-md w-full sm:w-auto">
          <VTextField
            v-model="consultationShareCode"
            disabled
            hide-details="auto"
            variant="solo"
            :style="{ width: `${consultationShareCode.length}ch` }"
          />
          <VBtn variant="plain" @click="copyShareCodeToClipboard">
            <VIcon icon="content_copy" class="text-secondary-text" />
          </VBtn>
        </div>
      </div>

      <VBtn
        :text="t('Legal Notice')"
        :href="t('https://arztkonsultation.de/impressum/')"
        variant="plain"
        :ripple="false"
        target="_blank"
        class="text-body-2 p-0 ml-auto"
      />
    </VCard>
  </VCard>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useCallStore } from '@/stores/call'
import { storeToRefs } from 'pinia'
import { getAkBaseUrl } from '@/global/environmentGetters'
import { useNoticeStore } from '@/stores/notice'
import { NoticeType } from '@/stores/models/Notice'
import { useRoute, useRouter } from 'vue-router'
import { routeNames } from '@/router/routes'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const { callConsultationRequestId, isInCall, roomId, accessToken, consultationShareCode } =
  storeToRefs(useCallStore())

const { joinCallWithConsultationShareCode, getConsultationShareCode, leaveCall } = useCallStore()
const { add: addNotice } = useNoticeStore()

onMounted(() => window.addEventListener('message', handleMessageEvent))

onMounted(() => {
  handleJoinCallWithConsultationShareCode()
})

watch(callConsultationRequestId, () => {
  if (callConsultationRequestId.value && !isJoinConsultationPath.value) {
    getConsultationShareCode(callConsultationRequestId.value)
  }
})

const isCallFound = ref(false)

const isJoinConsultationPath = computed(() => route.name === 'join-consultation')

const showCard = computed<boolean>(() => isInCall.value)

const callFrameSrc = computed<string | undefined>(() => {
  const baseUrl = getAkBaseUrl()

  if (!accessToken.value) {
    handleAccessTokenError()
    return
  }

  const base64AccessToken = btoa(accessToken.value)
  const url = new URL(`video/${roomId.value}?tid=${base64AccessToken}`, baseUrl)

  return url.toString()
})

function handleJoinCallWithConsultationShareCode() {
  const { consultationShareCode } = route.params

  if (isJoinConsultationPath.value && consultationShareCode) {
    joinCallWithConsultationShareCode(consultationShareCode as string).then(() => {
      if (!callConsultationRequestId.value) {
        router.push({ name: routeNames.DASHBOARD })
      }
    })
  }
}

function handleAccessTokenError() {
  addNotice({
    type: NoticeType.ERROR,
    message: 'Failed to join the call, because of invalid access token'
  })
  if (isJoinConsultationPath.value) {
    router.push({ name: routeNames.DASHBOARD })
  }
}

function copyShareCodeToClipboard() {
  if (consultationShareCode.value) {
    navigator.clipboard.writeText(consultationShareCode.value)
  }
}

function handleMessageEvent(msg: MessageEvent<string>) {
  if (msg.data === 'CALL_FOUND') {
    isCallFound.value = true
  }

  if (msg.data === 'CALL_CLOSED') {
    leaveCall()
    if (isJoinConsultationPath.value) {
      router.push({ name: routeNames.DASHBOARD })
    }
    return
  }

  if (msg.data === 'CALL_NOT_FOUND') {
    addNotice({
      type: NoticeType.ERROR,
      message: 'Failed to join the call'
    })
    if (isJoinConsultationPath.value) {
      router.push({ name: routeNames.DASHBOARD })
    }
    leaveCall()
    return
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: 750px;
  border: none;
}
</style>
