<template>
  <VChip
    id="consultation-type-chip"
    density="compact"
    v-bind="chipProps(consultationType, useColors)"
    :class="['mr-2', ...chipClasses(consultationType, useColors)]"
  >
    <span :class="chipContentClasses(consultationType, useColors)">
      {{ translateUrgencyDTO(props.consultationType, t) }}
    </span>
  </VChip>
</template>

<script setup lang="ts">
import { UrgencyDTO } from '@/generated'
import { translateUrgencyDTO } from '@/util/translations'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    consultationType: UrgencyDTO
    useColors?: boolean
    dark?: boolean
  }>(),
  { useColors: true, dark: false }
)
const { t } = useI18n()

interface ChipProps {
  variant: 'flat' | 'outlined'
  color: 'warning' | 'warning-dark' | 'grey-300' | 'error'
}

function chipProps(urgency: UrgencyDTO, useColors: boolean): ChipProps {
  if (useColors && urgency === UrgencyDTO.EMERGENCY) {
    return { variant: 'flat', color: 'error' }
  }
  if (useColors && urgency === UrgencyDTO.URGENT) {
    return { variant: 'flat', color: props.dark ? 'warning-dark' : 'warning' }
  }
  return { variant: 'outlined', color: 'grey-300' }
}

function chipClasses(urgency: UrgencyDTO, useColors: boolean): string[] {
  if (useColors && (urgency === UrgencyDTO.EMERGENCY || urgency === UrgencyDTO.URGENT)) {
    return ['text-surface']
  }
  return ['text-primary']
}

function chipContentClasses(urgency: UrgencyDTO, useColors: boolean): string[] {
  if (useColors && (urgency === UrgencyDTO.EMERGENCY || urgency === UrgencyDTO.URGENT)) {
    return []
  }
  return ['text-primary-text']
}
</script>

<style scoped>
.v-chip--variant-outlined {
  background: #fafafa;
}
</style>
