export const vuetifyDefaults = {
  VMain: {
    VSelect: {
      density: 'compact',
      class: ['rounded-lg'],
      variant: 'outlined',
      hideDetails: true,
      baseColor: 'grey-400',
      VIcon: {
        color: 'action-active'
      }
    },
    VTextField: {
      density: 'compact',
      variant: 'outlined',
      baseColor: 'grey-400',
      VField: {
        class: ['field-shadow-1']
      }
    },
    VTextarea: {
      variant: 'outlined',
      class: ['rounded-lg'],
      baseColor: 'grey-400',
      VField: {
        class: ['field-shadow-1']
      }
    }
  },
  VAutocomplete: {
    density: 'compact',
    clearable: true,
    clearIcon: 'custom:clear20',
    baseColor: 'grey-400',
    variant: 'outlined',
    VIcon: {
      color: 'action-active'
    },
    VChip: {
      VIcon: {
        color: 'grey-800'
      }
    }
  },
  VBtn: {
    class: ['text-none'],
    rounded: 'lg'
  },
  VCard: {
    rounded: 'lg',
    class: ['border-0']
  },
  VCheckboxBtn: {
    color: 'primary',
    baseColor: 'secondary-text'
  },
  VDialog: {
    VTextField: {
      density: 'compact',
      variant: 'outlined',
      baseColor: 'grey-400',
      VField: {
        class: ['field-shadow-1']
      }
    }
  },
  VRadioGroup: {
    trueIcon: 'custom:radioCheckedIcon',
    VRadio: {
      VLabel: {
        style: 'font-family: "Titillium Web"'
      },
      baseColor: 'secondary-text'
    }
  },
  VSnackbar: {
    rounded: 'lg',
    color: 'surface'
  },
  VChip: {
    class: ['text-chip'],
    VIcon: {
      icon: 'cancel',
      class: ['material-icons-round', 'opacity-70']
    }
  }
}
