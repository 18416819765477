import { ShiftApi, type ShiftDTO } from '@/generated'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'

export class ShiftService {
  static readonly shiftApi = new ShiftApi(globalApiConfig)

  static async createAndBeginShift(request: ShiftDTO): Promise<void> {
    await ShiftService.shiftApi.createAndBeginShift(request)
  }
}
