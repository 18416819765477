export const MedicalFieldsDTO = {
  ANESTHESIOLOGY: 'anesthesiology',
  OCCUPATIONAL_MEDICINE: 'occupational_medicine',
  OPHTHALMOLOGY: 'ophthalmology',
  GENERAL_SURGERY: 'general_surgery',
  VASCULAR_SURGERY: 'vascular_surgery',
  CARDIAC_SURGERY_CARDIOTHORACIC_SURGERY: 'cardiac_surgery_cardiothoracic_surgery',
  PEDIATRIC_SURGERY: 'pediatric_surgery',
  ORTHOPEDICS_AND_TRAUMA_SURGERY: 'orthopedics_and_trauma_surgery',
  THORACIC_SURGERY: 'thoracic_surgery',
  VISCERAL_SURGERY: 'visceral_surgery',
  GYNECOLOGY: 'gynecology',
  DERMATOLOGY: 'dermatology',
  INTERNAL_MEDICINE: 'internal_medicine',
  INTERNAL_MEDICINE_AND_ANGIOLOGY: 'internal_medicine_and_angiology',
  INTERNAL_MEDICINE_AND_ENDOCRINOLOGY_AND_DIABETOLOGY:
    'internal_medicine_and_endocrinology_and_diabetology',
  INTERNAL_MEDICINE_AND_GASTROENTEROLOGY: 'internal_medicine_and_gastroenterology',
  INTERNAL_MEDICINE_AND_HEMATOLOGY_AND_ONCOLOGY: 'internal_medicine_and_hematology_and_oncology',
  INTERNAL_MEDICINE_AND_CARDIOLOGY: 'internal_medicine_and_cardiology',
  INTERNAL_MEDICINE_AND_NEPHROLOGY: 'internal_medicine_and_nephrology',
  INTERNAL_MEDICINE_AND_PULMONOLOGY: 'internal_medicine_and_pulmonology',
  INTERNAL_MEDICINE_AND_RHEUMATOLOGY: 'internal_medicine_and_rheumatology',
  PEDIATRICS: 'pediatrics',
  MICROBIOLOGY_VIROLOGY_AND_INFECTION_EPIDEMIOLOGY:
    'microbiology_virology_and_infection_epidemiology',
  ORAL_AND_MAXILLOFACIAL_SURGERY: 'oral_and_maxillofacial_surgery',
  NEUROSURGERY: 'neurosurgery',
  NEUROLOGY: 'neurology',
  PHARMACOLOGY_AND_TOXICOLOGY: 'pharmacology_and_toxicology',
  PSYCHIATRY_AND_PSYCHOTHERAPY: 'psychiatry_and_psychotherapy',
  NEURORADIOLOGY: 'neuroradiology',
  RADIATION_THERAPY: 'radiation_therapy',
  UROLOGY: 'urology',
  DIABETOLOGY: 'diabetology',
  NUTRITIONAL_MEDICINE: 'nutritional_medicine',
  GERIATRICS: 'geriatrics',
  IMMUNOLOGY: 'immunology',
  INFECTIOLOGY: 'infectiology',
  INTENSIVE_CARE_MEDICINE: 'intensive_care_medicine',
  PALLIATIVE_MEDICINE: 'palliative_medicine',
  SPECIALIZED_PAIN_THERAPY: 'specialized_pain_therapy'
} as const

export type MedicalFieldsDTO = (typeof MedicalFieldsDTO)[keyof typeof MedicalFieldsDTO]
