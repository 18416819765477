<template>
  <VContainer fluid class="m-0 pl-6 pr-6 w-full">
    <VRow align="center" class="flex-nowrap gap-2 lg:gap-4" no-gutters>
      <VCol class="flex-grow-0">
        <VImg
          data-testid="menu-icon"
          :width="35"
          :height="40"
          :src="logo"
          alt="Telehealth Logo"
          draggable="false"
          class="cursor-pointer"
          @click="routeToDashboard"
        />
      </VCol>
      <VCol class="flex-grow-0 max-lg:hidden">
        <VBtn
          data-testid="menu-dashboard-button"
          :to="{ name: routeNames.DASHBOARD }"
          :active="false"
          class="button-large"
          color="primary"
        >
          {{ t('Dashboard') }}
        </VBtn>
      </VCol>
      <VCol data-testid="menu-patient-monitoring-button" class="flex-grow-0 max-lg:hidden">
        <VBtn
          :to="{ name: routeNames.VITAL_DATA }"
          :active="false"
          class="button-large mr-4"
          color="primary"
        >
          {{ t('Vital data') }}
        </VBtn>
      </VCol>
      <VCol class="max-lg:hidden" cols="3">
        <PatientSearch />
      </VCol>
      <VSpacer />
      <VCol class="flex-grow-0 max-lg:hidden">
        <VBtn
          data-testid="menu-request-button"
          :to="{ name: routeNames.CREATE_CONSULTATION }"
          :active="false"
          :disabled="disableConsultationButton"
          class="button-large"
          color="primary"
          prepend-icon="add"
        >
          <span class="max-xl:hidden">
            {{ t('Create consultation') }}
          </span>
        </VBtn>
      </VCol>
      <VCol
        data-testid="menu-emergency-button"
        class="flex-grow-0"
        :class="{ hidden: isMobileMenuDrawerOpened }"
      >
        <VTooltip location="bottom" :disabled="!disableEmergencyButton">
          {{ t('No physicians available in the Intensive Care Department for emergency calls.') }}
          <template #activator="activator">
            <span v-bind="activator.props">
              <VBtn
                :active="false"
                class="button-large"
                color="secondary"
                :disabled="disableEmergencyButton || hasGodModeShift"
                @click="openEmergencyDialog"
              >
                <template #prepend>
                  <VIcon icon="emergency" class="material-icons-round" />
                </template>
                {{ t('Emergency') }}
              </VBtn>
            </span>
          </template>
        </VTooltip>
      </VCol>
      <VCol class="flex-grow-0">
        <VBtn
          :active="false"
          data-testid="btn-notification-bell"
          :disabled="hasGodModeShift"
          @click="routeToNotificationCenter"
        >
          <template v-if="newNotificationsAvailable">
            <VBadge dot color="success-badge">
              <VIcon icon="notifications" size="32px" class="text-secondary-text" />
            </VBadge>
          </template>
          <template v-else>
            <VIcon icon="notifications" size="32px" class="text-secondary-text" />
          </template>
        </VBtn>
      </VCol>
      <VCol class="flex-grow-0 max-lg:hidden">
        <UserMenu />
      </VCol>
      <VCol class="flex-grow-0 lg:hidden">
        <v-app-bar-nav-icon
          :icon="isMobileMenuDrawerOpened ? '$close' : '$menu'"
          variant="text"
          @click.stop="toggleMobileMenuDrawer"
        />
      </VCol>
    </VRow>

    <CreateEmergencyConsultationDialog v-model="showCreateEmergencyConsultationDialog" />
  </VContainer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import logo from '@/assets/tcclogo.png'
import UserMenu from '@/components/navigation/UserMenu.vue'
import { routeNames } from '@/router/routes'
import CreateEmergencyConsultationDialog from '@/components/navigation/CreateEmergencyConsultationDialog.vue'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useCaseStore } from '@/stores/case'
import PatientSearch from '@/components/navigation/patientsearch/PatientSearch.vue'
import { useRouter } from 'vue-router'
import { useRepeatWhileMounted } from '@/composables/repeatWhileMounted'
import { useNotificationStore } from '@/stores/notification'
import { getRefreshIntervalNotificationCenter } from '@/global/environmentGetters'
import { useUserStore } from '@/stores/user'

const { t } = useI18n()
const router = useRouter()
const { caseList } = storeToRefs(useCaseStore())
const isMobileMenuDrawerOpened = defineModel<boolean>()

const { hasGodModeShift } = storeToRefs(useUserStore())

const showCreateEmergencyConsultationDialog = ref(false)

const disableEmergencyButton = computed(() => caseList.value.every((c) => c.emergencyUnavailable))

const disableConsultationButton = computed(() => hasGodModeShift.value)

const { newNotificationsAvailable } = storeToRefs(useNotificationStore())

useRepeatWhileMounted(
  useNotificationStore().refreshNotificationList,
  getRefreshIntervalNotificationCenter()
)

function toggleMobileMenuDrawer() {
  isMobileMenuDrawerOpened.value = !isMobileMenuDrawerOpened.value
}

async function routeToDashboard() {
  await router.push({ name: routeNames.DASHBOARD })
}

async function routeToNotificationCenter() {
  await router.push({ name: routeNames.NOTIFICATION_CENTER })
}

function openEmergencyDialog() {
  showCreateEmergencyConsultationDialog.value = true
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
