<template>
  <div class="flex flex-col items-start gap-4 mt-6 mb-6">
    <VBtn
      v-if="!!showButton"
      data-testid="page-header-button"
      class="button-large button-outline-primary"
      size="large"
      color="primary"
      prepend-icon="arrow_back"
      variant="outlined"
      @click="onClick"
    >
      {{ showButton }}
    </VBtn>
    <h2 data-testid="page-header-heading" class="text-h2">
      <slot></slot>
    </h2>
    <h4 v-if="subHeading" data-testid="page-header-subheading" class="text-h4">
      {{ subHeading }}
    </h4>
    <span
      v-if="!!text"
      data-testid="page-header-text"
      class="text-body-1 text-secondary-text max-width-800"
    >
      {{ text }}
    </span>
    <slot name="details" />
  </div>
</template>

<script setup lang="ts">
import type { routeNames } from '@/router/routes'
import { useRouter } from 'vue-router'

export interface PageHeaderProps {
  showButton?: string
  buttonTarget?: routeNames | 'back'
  subHeading?: string
  text?: string
}

const props = withDefaults(defineProps<PageHeaderProps>(), {
  showButton: undefined,
  buttonTarget: 'back',
  subHeading: undefined,
  text: undefined
})

const router = useRouter()

function onClick(): void {
  if (props.buttonTarget === 'back') {
    router.back()
  } else {
    router.push({ name: props.buttonTarget })
  }
}
</script>

<style scoped>
.max-width-800 {
  max-width: 800px;
}
</style>
