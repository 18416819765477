import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { type Ref } from 'vue'

export type PatientDataSettings = {
  organSupport: {
    vasopressors: boolean
    ventilation: boolean
    dialysis: boolean
    ecmo: boolean
    impella: boolean
  }
  laboratoryValues: {
    urineExcretion: boolean
    lactate: boolean
    potassium: boolean
  }
  scorings: {
    sofa: boolean
    apacheII: boolean
  }
}

export const usePatientDataSettingsStore = defineStore('patientDataSettings', () => {
  const patientDataSettings: Ref<PatientDataSettings> = useLocalStorage(
    'patientDataSettingsFields',
    {
      organSupport: {
        vasopressors: true,
        ventilation: true,
        dialysis: true,
        ecmo: true,
        impella: true
      },
      laboratoryValues: {
        urineExcretion: true,
        lactate: true,
        potassium: true
      },
      scorings: {
        sofa: true,
        apacheII: true
      }
    }
  )

  function updatePatientDataSettings(newSettings: PatientDataSettings) {
    patientDataSettings.value = newSettings
  }

  return { patientDataSettings, updatePatientDataSettings }
})
