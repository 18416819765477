interface Identifiable {
  id: string
}

export function arrayToRecord<T extends Identifiable>(array: T[]): Record<string, T> {
  return array.reduce((record: Record<string, T>, item) => {
    record[item.id] = item
    return record
  }, {})
}
