<template>
  <VContainer class="flex flex-col p-4">
    <PageHeader :show-button="t('Back')" button-target="back">
      {{ t('Patient data') }}
    </PageHeader>
    <PatientDataSettingsForm />
  </VContainer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import PageHeader from '@/components/page/PageHeader.vue'
import PatientDataSettingsForm from '@/components/patient/components/molecules/PatientDataSettingsForm.vue'

const { t } = useI18n()
</script>
