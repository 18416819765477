/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import type { VitalSignsSortBy } from '@/stores/models/VitalSignsSortBy'
import { VitalSignsKeys } from './VitalSignsKeys'

export const getVitalSignsSortByOptions = (
  t: Function
): { label: string; value: VitalSignsSortBy }[] => [
  {
    label: t('Alphabetically'),
    value: {
      sortBy: VitalSignsKeys.PATIENT_NAME,
      direction: 'ASC'
    }
  },
  {
    label: t('Highest heart rate'),
    value: {
      sortBy: VitalSignsKeys.HEART_RATE,
      direction: 'DESC'
    }
  },
  {
    label: t('Lowest heart rate'),
    value: {
      sortBy: VitalSignsKeys.HEART_RATE,
      direction: 'ASC'
    }
  },
  {
    label: t('Highest respiratory rate'),
    value: {
      sortBy: VitalSignsKeys.RR,
      direction: 'DESC'
    }
  },
  {
    label: t('Lowest respiratory rate'),
    value: {
      sortBy: VitalSignsKeys.RR,
      direction: 'ASC'
    }
  },
  {
    label: t('Highest body temperature'),
    value: {
      sortBy: VitalSignsKeys.TEMP,
      direction: 'DESC'
    }
  },
  {
    label: t('Lowest body temperature'),
    value: {
      sortBy: VitalSignsKeys.TEMP,
      direction: 'ASC'
    }
  },
  {
    label: t('Highest systolic arterial pressure'),
    value: {
      sortBy: VitalSignsKeys.INV_S_BP,
      direction: 'DESC'
    }
  },
  {
    label: t('Lowest systolic arterial pressure'),
    value: {
      sortBy: VitalSignsKeys.INV_S_BP,
      direction: 'ASC'
    }
  },
  {
    label: t('Highest diastolic arterial pressure'),
    value: {
      sortBy: VitalSignsKeys.INV_D_BP,
      direction: 'DESC'
    }
  },
  {
    label: t('Lowest diastolic arterial pressure'),
    value: {
      sortBy: VitalSignsKeys.INV_D_BP,
      direction: 'ASC'
    }
  },
  {
    label: t('Highest mean arterial pressure'),
    value: {
      sortBy: VitalSignsKeys.INV_M_BP,
      direction: 'DESC'
    }
  },
  {
    label: t('Lowest mean arterial pressure'),
    value: {
      sortBy: VitalSignsKeys.INV_M_BP,
      direction: 'ASC'
    }
  },
  {
    label: t('Highest systolic blood pressure non-invasive'),
    value: {
      sortBy: VitalSignsKeys.NON_INV_S_BP,
      direction: 'DESC'
    }
  },
  {
    label: t('Lowest systolic blood pressure non-invasive'),
    value: {
      sortBy: VitalSignsKeys.NON_INV_S_BP,
      direction: 'ASC'
    }
  },
  {
    label: t('Highest diastolic blood pressure non-invasive'),
    value: {
      sortBy: VitalSignsKeys.NON_INV_D_BP,
      direction: 'DESC'
    }
  },
  {
    label: t('Lowest diastolic blood pressure non-invasive'),
    value: {
      sortBy: VitalSignsKeys.NON_INV_D_BP,
      direction: 'ASC'
    }
  },
  {
    label: t('Highest peripheral oxygen saturation'),
    value: {
      sortBy: VitalSignsKeys.SPO2,
      direction: 'DESC'
    }
  },
  {
    label: t('Lowest peripheral oxygen saturation'),
    value: {
      sortBy: VitalSignsKeys.SPO2,
      direction: 'ASC'
    }
  }
]
