import Keycloak, { type KeycloakProfile } from 'keycloak-js'
import { useUserStore } from '@/stores/user'
import { getKeycloakClientId, getKeycloakRealm, getKeycloakUrl } from '@/global/environmentGetters'
import { requireString } from '@/util/requireString'

export class AuthenticationService {
  private static readonly MIN_REMAINING_TOKEN_VALIDITY_SECONDS = 5

  private static keycloakInstance: Keycloak

  static async initializeKeycloak(): Promise<void> {
    AuthenticationService.keycloakInstance = new Keycloak({
      realm: getKeycloakRealm(),
      url: getKeycloakUrl(),
      clientId: getKeycloakClientId()
    })

    try {
      const authenticated = await AuthenticationService.keycloakInstance.init({
        onLoad: 'login-required'
      })
      if (authenticated) {
        const userProfile: KeycloakProfile =
          await AuthenticationService.keycloakInstance.loadUserProfile()
        const medicalTitle =
          AuthenticationService.keycloakInstance.tokenParsed?.['telehealth']?.['medical_title']
        useUserStore().setUser({
          userName: requireString(userProfile.username),
          title: medicalTitle,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: requireString(userProfile.email),
          hasGodMode: false,
          isChiefPhysician: false
        })
      } else {
        useUserStore().setUser(undefined)
      }
    } catch (error) {
      // TODO: some advanced logging, maybe define own errortype
      console.error('Keycloak init error: ' + (error as Error).message)
    }
  }

  static async updateAccessToken(): Promise<string> {
    await AuthenticationService.keycloakInstance.updateToken(
      AuthenticationService.MIN_REMAINING_TOKEN_VALIDITY_SECONDS
    )

    if (AuthenticationService.keycloakInstance.isTokenExpired()) {
      AuthenticationService.keycloakInstance.clearToken()
      await AuthenticationService.logout()
    }

    if (!AuthenticationService.keycloakInstance.token) {
      throw Error('could not obtain keycloak token')
    }

    return AuthenticationService.keycloakInstance.token
  }

  static async logout(): Promise<void> {
    localStorage.clear()
    try {
      return AuthenticationService.keycloakInstance.logout({
        redirectUri: window.location.origin
      })
    } catch (e) {
      //todo: show a message to the user when the error-display component is there
      console.error(e)
    }
  }
}
