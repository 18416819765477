<template>
  <VCard class="p-6">
    <div class="flex flex-col items-center sm:flex-row sm:items-start sm:justify-between gap-4">
      <div class="w-full sm:w-[240px] flex flex-col gap-4">
        <h5 class="text-h5">{{ t('Settings') }}</h5>
        <span class="text-body-2 text-secondary-text"
          >{{
            t('You can select up to 5 additional live data values to be displayed for a patient.')
          }}&nbsp;{{ t('The standard data values are always displayed.') }}</span
        >
      </div>
      <div class="w-full sm:w-[60%]">
        <VForm @submit.prevent="onSubmit">
          <div class="flex flex-col">
            <div class="flex flex-col">
              <div class="text-subtitle-1 mb-1">
                {{ t('Organ support') }}
              </div>

              <div class="flex flex-col">
                <VCheckboxBtn
                  v-model="patientDataSettings.organSupport.vasopressors"
                  :ripple="false"
                  inline
                  class="mr-4"
                >
                  <template #label>
                    <span class="ml-2">{{ t('Vasopressors') }}</span>
                  </template>
                </VCheckboxBtn>
              </div>

              <div class="flex flex-col">
                <VCheckboxBtn
                  v-model="patientDataSettings.organSupport.ventilation"
                  :ripple="false"
                  inline
                  class="mr-4"
                >
                  <template #label>
                    <span class="ml-2">{{ t('Ventilation') }}</span>
                  </template>
                </VCheckboxBtn>
              </div>

              <div class="flex flex-col">
                <VCheckboxBtn
                  v-model="patientDataSettings.organSupport.dialysis"
                  :ripple="false"
                  inline
                  class="mr-4"
                >
                  <template #label>
                    <span class="ml-2">{{ t('Dialysis') }}</span>
                  </template>
                </VCheckboxBtn>
              </div>

              <div class="flex flex-col">
                <VCheckboxBtn
                  v-model="patientDataSettings.organSupport.ecmo"
                  :ripple="false"
                  inline
                  class="mr-4"
                >
                  <template #label>
                    <span class="ml-2">{{ t('ECMO') }}</span>
                  </template>
                </VCheckboxBtn>
              </div>

              <div class="flex flex-col">
                <VCheckboxBtn
                  v-model="patientDataSettings.organSupport.impella"
                  :ripple="false"
                  inline
                  class="mr-4"
                >
                  <template #label>
                    <span class="ml-2">{{ t('Impella') }}</span>
                  </template>
                </VCheckboxBtn>
              </div>

              <DividerLine class="my-6" />
            </div>

            <div class="flex flex-col">
              <div class="text-subtitle-1 mb-1">
                {{ t('Laboratory values') }}
              </div>

              <div class="flex flex-col">
                <VCheckboxBtn
                  v-model="patientDataSettings.laboratoryValues.urineExcretion"
                  :ripple="false"
                  inline
                  class="mr-4"
                >
                  <template #label>
                    <span class="ml-2">{{ t('Urine excretion') }}</span>
                  </template>
                </VCheckboxBtn>
              </div>

              <div class="flex flex-col">
                <VCheckboxBtn
                  v-model="patientDataSettings.laboratoryValues.lactate"
                  :ripple="false"
                  inline
                  class="mr-4"
                >
                  <template #label>
                    <span class="ml-2">{{ t('Lactate') }}</span>
                  </template>
                </VCheckboxBtn>
              </div>

              <div class="flex flex-col">
                <VCheckboxBtn
                  v-model="patientDataSettings.laboratoryValues.potassium"
                  :ripple="false"
                  inline
                  class="mr-4"
                >
                  <template #label>
                    <span class="ml-2">{{ t('Potassium') }}</span>
                  </template>
                </VCheckboxBtn>
              </div>

              <DividerLine class="my-6" />
            </div>

            <div class="flex flex-col">
              <div class="text-subtitle-1 mb-1">
                {{ t('Scorings') }}
              </div>

              <div class="flex flex-col">
                <VCheckboxBtn
                  v-model="patientDataSettings.scorings.sofa"
                  :ripple="false"
                  inline
                  class="mr-4"
                >
                  <template #label>
                    <span class="ml-2">{{ t('SOFA') }}</span>
                  </template>
                </VCheckboxBtn>
                <span class="text-body-2">{{ t('Brief description (0-71)') }}</span>
              </div>

              <div class="flex flex-col">
                <VCheckboxBtn
                  v-model="patientDataSettings.scorings.apacheII"
                  :ripple="false"
                  inline
                  class="mr-4"
                >
                  <template #label>
                    <span class="ml-2">{{ t('APACHE II') }}</span>
                  </template>
                </VCheckboxBtn>
                <span class="text-body-2">{{
                  t(
                    'General measure of disease severity based on current physiological measurements, age and previous health conditions (0-71)'
                  )
                }}</span>
              </div>

              <DividerLine class="my-6" />
            </div>

            <div class="flex flex-col">
              <div class="text-subtitle-1 mb-1">
                {{ t('AI prediction') }}
              </div>

              <VSwitch :disabled="true" :label="t('Enable AI predictive scoring ')" />
            </div>

            <DividerLine class="my-6" />

            <div class="flex gap-4 mb-4 mt-4">
              <VBtn
                data-testid="consultation-request-submit-button"
                :disabled="hasGodModeShift"
                class="button-large"
                size="large"
                variant="flat"
                color="primary"
                type="submit"
              >
                {{ t('Save') }}
              </VBtn>
            </div>
          </div>
        </VForm>
      </div>
    </div>
  </VCard>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'
import { useNoticeStore } from '@/stores/notice'
import { NoticeType } from '@/stores/models/Notice'
import { storeToRefs } from 'pinia'
import DividerLine from '@/atoms/DividerLine.vue'
import { usePatientDataSettingsStore, type PatientDataSettings } from '@/stores/patientDataSettings'

const router = useRouter()
const noticeStore = useNoticeStore()
const patientDataSettingsStore = usePatientDataSettingsStore()
const { t } = useI18n()
const { hasGodModeShift } = storeToRefs(useUserStore())

const patientDataSettings = ref<PatientDataSettings>(patientDataSettingsStore.patientDataSettings)

async function onSubmit(): Promise<void> {
  patientDataSettingsStore.updatePatientDataSettings(patientDataSettings.value)
  noticeStore.add({
    type: NoticeType.SUCCESS,
    message: t('Settings saved')
  })
  router.back()
}
</script>
