import { createI18n } from 'vue-i18n'
import de from '@/i18n/locales/de.json'
import en from '@/i18n/locales/en.json'
import { en as enVuetify, de as deVuetify } from 'vuetify/locale'

export const languages = {
  GERMAN: 'de',
  ENGLISH: 'en'
} as const
export type languages = (typeof languages)[keyof typeof languages]

const dateOfBirth: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  timeZone: 'utc'
}

const consultationLogDate: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
}

const consultationLogDateTime: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  minute: '2-digit',
  hour: '2-digit'
}

const fileUploadedAtDateTime: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  minute: '2-digit',
  hour: '2-digit'
}

const todayDateTime: Intl.DateTimeFormatOptions = {
  minute: '2-digit',
  hour: '2-digit'
}

const beforeTodayDateTime: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  minute: '2-digit',
  hour: '2-digit'
}

const i18n = createI18n({
  legacy: false,
  locale: languages.ENGLISH,
  fallbackLocale: languages.ENGLISH,
  messages: {
    de: { $vuetify: { ...deVuetify }, ...de },
    en: { $vuetify: { ...enVuetify }, ...en }
  },
  datetimeFormats: {
    [languages.GERMAN]: {
      dateOfBirth,
      consultationLogDate,
      consultationLogDateTime,
      fileUploadedAtDateTime,
      beforeTodayDateTime,
      todayDateTime
    },
    [languages.ENGLISH]: {
      dateOfBirth,
      consultationLogDate,
      consultationLogDateTime,
      fileUploadedAtDateTime,
      beforeTodayDateTime,
      todayDateTime
    }
  }
})

export function changeLanguage(language: languages) {
  i18n.global.locale.value = language
}

export function validateLanguageCode(languageCode: string): void {
  if (!(Object.values(languages) as string[]).includes(languageCode)) {
    throw new Error(`invalid language code ${languageCode}`)
  }
}

export default i18n
