import {
  ConsultationRequestApi,
  type ConsultationRequestCreationDTO,
  type ConsultationRequestReportDTO,
  type GetConsultationRequestReportResponse,
  type GetConsultationRequestsResponse,
  UrgencyDTO
} from '@/generated'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'

export class ConsultationRequestService {
  static readonly consultationApi = new ConsultationRequestApi(globalApiConfig)

  static async getIncomingConsultationRequests(): Promise<GetConsultationRequestsResponse> {
    return (
      await ConsultationRequestService.consultationApi.getIncomingConsultationRequests(
        new Set([UrgencyDTO.URGENT, UrgencyDTO.ROUTINE, UrgencyDTO.EMERGENCY])
      )
    ).data
  }

  static async getOutgoingConsultationRequests(): Promise<GetConsultationRequestsResponse> {
    return (await ConsultationRequestService.consultationApi.getOutgoingConsultationRequests()).data
  }

  static async createConsultationRequest(request: ConsultationRequestCreationDTO): Promise<string> {
    return (await ConsultationRequestService.consultationApi.createConsultationRequest(request))
      .data
  }

  static async acceptConsultationRequest(id: string): Promise<void> {
    await ConsultationRequestService.consultationApi.acceptConsultationRequest(id)
  }

  static async declineConsultationRequest(id: string): Promise<void> {
    await ConsultationRequestService.consultationApi.declineConsultationRequest(id)
  }

  static async deleteConsultationRequest(id: string): Promise<void> {
    await ConsultationRequestService.consultationApi.deleteConsultationRequest(id)
  }

  static async getConsultationRequestReport(
    id: string
  ): Promise<GetConsultationRequestReportResponse> {
    return (await ConsultationRequestService.consultationApi.getConsultationRequestReport(id)).data
  }

  static async putConsultationRequestReport(
    id: string,
    report: ConsultationRequestReportDTO,
    finalize: boolean = false
  ): Promise<void> {
    await ConsultationRequestService.consultationApi.putConsultationRequestReport(
      id,
      report,
      finalize
    )
  }

  static async createEmergencyRequest(caseId: string): Promise<string> {
    const intensiveCareLiteral = 'intensive_care_medicine'

    const submittedConsultationRequest: ConsultationRequestCreationDTO = {
      caseId,
      medicalFieldId: intensiveCareLiteral,
      question: '',
      diagnosis: '',
      urgency: UrgencyDTO.EMERGENCY
    }
    return await ConsultationRequestService.createConsultationRequest(submittedConsultationRequest)
  }
}
