import { defineStore } from 'pinia'
import { type Ref, ref } from 'vue'

export const usePatientListStore = defineStore('patientList', () => {
  const numberOfItemsToShow: Ref<number> = ref(30)

  function increaseItemsToShowByTen() {
    numberOfItemsToShow.value += 10
  }

  return { numberOfItemsToShow, increaseItemsToShowByTen }
})
