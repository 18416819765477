<template>
  <VAvatar size="large">
    <span class="text-h5 text-secondary-text">{{ userInitials }}</span>
  </VAvatar>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useUserStore } from '@/stores/user'

const userInitials = computed(() => {
  const firstName = useUserStore().user!.firstName ?? ''
  const lastName = useUserStore().user!.lastName ?? ''

  return `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}`
})
</script>
