import { computed, ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { type CallAccessDTO } from '@/generated'
import { CallService } from '@/services/telehealthApi/CallService'
import { NoticeType } from '@/stores/models/Notice'
import { useNoticeStore } from '@/stores/notice'

export const useCallStore = defineStore('call', () => {
  const noticeStore = useNoticeStore()

  const callAccess: Ref<CallAccessDTO | undefined> = ref()

  const callConsultationRequestId: Ref<string | undefined> = ref()

  const isInCall = computed<boolean>(() => !!callAccess.value)

  const roomId = computed<number | undefined>(() => callAccess.value?.roomId)

  const accessToken = computed<string | undefined>(() => callAccess.value?.accessToken)

  const consultationShareCode = ref<string | undefined>()

  async function startCall(consultationRequestId: string): Promise<void> {
    try {
      callAccess.value = await CallService.joinCall(consultationRequestId)
      callConsultationRequestId.value = consultationRequestId
    } catch (err) {
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Failed to start the call'
      })
    }
  }

  async function getConsultationShareCode(consultationRequestId: string): Promise<void> {
    try {
      const _consultationShareCode = (
        await CallService.getConsultationShareCode(consultationRequestId)
      ).consultationShareCode

      consultationShareCode.value = `http://${window.location.host}/${_consultationShareCode}`
    } catch (err) {
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Failed to get the share code'
      })
    }
  }

  async function joinCall(consultationRequestId: string): Promise<void> {
    try {
      callAccess.value = await CallService.joinCall(consultationRequestId)
      callConsultationRequestId.value = consultationRequestId
    } catch (err) {
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Failed to join the call'
      })
    }
  }

  async function joinCallWithConsultationShareCode(consultationShareCode: string): Promise<void> {
    try {
      callAccess.value = await CallService.joinWithConsultationShareCode(consultationShareCode)
      callConsultationRequestId.value = callAccess.value.consultationRequestId
    } catch (err) {
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Failed to join the call with the share code'
      })
    }
  }

  async function endCall(consultationRequestId: string): Promise<void> {
    try {
      await CallService.endCall(consultationRequestId)
      callAccess.value = undefined
      callConsultationRequestId.value = undefined
    } catch (err) {
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Failed to end the call'
      })
    }
  }

  function leaveCall() {
    callAccess.value = undefined
    callConsultationRequestId.value = undefined
  }

  return {
    isInCall,
    roomId,
    accessToken,
    startCall,
    joinCall,
    endCall,
    leaveCall,
    callAccess,
    callConsultationRequestId,
    consultationShareCode,
    getConsultationShareCode,
    joinCallWithConsultationShareCode
  }
})
