import type { CaseDTO } from '@/generated'

export function compareCases(a: CaseDTO, b: CaseDTO) {
  const aLastName = a.patient.lastName.toLowerCase()
  const bLastName = b.patient.lastName.toLowerCase()
  const aFirstName = a.patient.firstName.toLowerCase()
  const bFirstName = b.patient.firstName.toLowerCase()
  if (aLastName !== bLastName) {
    return aLastName.localeCompare(bLastName, 'de')
  } else if (aFirstName !== bFirstName) {
    return aFirstName.localeCompare(bFirstName, 'de')
  } else {
    if (a.externalId < b.externalId) return -1
    if (a.externalId > b.externalId) return 1
    return 0
  }
}
