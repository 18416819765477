import type { NamedValue } from 'vue-i18n'

export const NoticeType = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
}

export type NoticeType = (typeof NoticeType)[keyof typeof NoticeType]

export interface Notice {
  type: NoticeType
  message: string
  messageParameters?: NamedValue
}
