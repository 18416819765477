<template>
  <VContainer class="pt-0">
    <div class="flex justify-between my-4">
      <div class="flex flex-col">
        <h2 class="text-primary-text text-h2 mb-4">
          {{ h2text }}
        </h2>
        <div class="flex flex-col gap-4 items-start">
          <VBtn
            :active="false"
            class="sm:hidden button-medium mr-4 p-0"
            variant="text"
            color="primary"
            prepend-icon="settings"
            :to="routeNames.VITAL_DATA_SETTINGS"
          >
            {{ t('Patient data') }}
          </VBtn>

          <div class="flex flex-row gap-2 flex-wrap">
            <PatientFilterButton />
            <PatientFilterChips />
          </div>

          <VBtn
            color="primary"
            :active="false"
            class="sm:hidden button-medium mr-4"
            variant="outlined"
            size="medium"
            append-icon="keyboard_arrow_down"
            @click.prevent
          >
            {{ getSortByChosenOptionLabel }}
            <VitalSignsSortingDialog />
          </VBtn>
        </div>
      </div>

      <div class="hidden sm:flex flex-col justify-between items-end">
        <VBtn
          :active="false"
          class="button-medium flex-1 mb-4 mr-4 p-0"
          variant="text"
          color="primary"
          prepend-icon="settings"
          :to="routeNames.VITAL_DATA_SETTINGS"
        >
          {{ t('Patient data') }}
        </VBtn>
        <VBtn
          color="primary"
          :active="false"
          class="button-medium mr-4"
          variant="outlined"
          size="medium"
          append-icon="keyboard_arrow_down"
          @click.prevent
        >
          {{ getSortByChosenOptionLabel }}
          <VitalSignsSortingDialog />
        </VBtn>
      </div>
    </div>

    <div
      v-if="caseWithVitalSignsList.length === 0 && (casesLoading || vitalSignsLoading)"
      class="my-2"
    >
      <VProgressLinear indeterminate />
    </div>

    <PatientCard
      v-for="patientCase in caseWithVitalSignsList"
      :key="patientCase.id"
      :caze="patientCase"
      :vital-signs="patientCase.vitalSigns"
    />

    <div v-if="!casesLoading && !vitalSignsLoading" class="flex justify-center py-4">
      <span
        v-if="noCasesAfterFiltering"
        data-testid="no-cases-after-filtering-message"
        class="text-body-2 text-secondary-text"
      >
        {{ t("No results. Please ensure that you haven't applied too many filters.") }}
      </span>
      <span
        v-else-if="noMoreCasesAvailable"
        data-testid="all-patients-shown-message"
        class="text-body-2 text-secondary-text"
      >
        {{ t('All patients are shown') }}
      </span>
      <VBtn
        v-else
        data-testid="show-more-button"
        class="button-large px-3"
        size="large"
        variant="text"
        color="primary"
        @click="onShowMore()"
      >
        {{ t('Show more') }}
      </VBtn>
    </div>
  </VContainer>
</template>

<script setup lang="ts">
import { type CaseDTO, type HospitalDTO } from '@/generated'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useCaseStore } from '@/stores/case'
import { usePatientListStore } from '@/stores/patientList'
import { storeToRefs } from 'pinia'
import PatientFilterButton from '@/components/patient/filter/PatientFilterButton.vue'
import PatientFilterChips from '@/components/patient/filter/PatientFilterChips.vue'
import PatientCard from '../molecules/PatientCard.vue'
import { routeNames } from '@/router/routes'
import { useRepeatWhileMounted } from '@/composables/repeatWhileMounted'
import { getVitalSignsSortByOptions } from '@/components/patient/models/VitalSignsSortByOptions'
import VitalSignsSortingDialog from '@/components/patient/components/molecules/VitalSignsSortingDialog.vue'
import { getRefreshIntervalVitalSigns } from '@/global/environmentGetters'

const { t } = useI18n()
const patientListStore = usePatientListStore()
const caseStore = useCaseStore()

const { casesLoading, vitalSignsLoading, patientFilter, caseWithVitalSignsList, vitalSignsSortBy } =
  storeToRefs(caseStore)

useRepeatWhileMounted(caseStore.getVitalSigns, getRefreshIntervalVitalSigns())

const { numberOfItemsToShow } = storeToRefs(patientListStore)
const { increaseItemsToShowByTen } = patientListStore

const getSortByChosenOptionLabel = computed<string>(() => {
  return (
    getVitalSignsSortByOptions(t).find(
      (el) =>
        el.value.direction === vitalSignsSortBy.value.direction &&
        el.value.sortBy === vitalSignsSortBy.value.sortBy
    )?.label || t('Sort by...')
  )
})

const numberOfCasesReceived = computed<number>(() => {
  return caseWithVitalSignsList.value.length
})

const numberOfCasesAfterFiltering = computed<number>(() => {
  return filteredCases.value.length
})

const filteredCases = computed<CaseDTO[]>(() => {
  return caseWithVitalSignsList.value.filter(filterCases)
})

const noMoreCasesAvailable = computed<boolean>(() => {
  return filteredCases.value.length <= numberOfItemsToShow.value
})

const noCasesAfterFiltering = computed<boolean>(() => {
  return filteredCases.value.length === 0
})

const h2text = computed<string>(() => {
  if (numberOfCasesReceived.value === numberOfCasesAfterFiltering.value) {
    return t('All Patients ({count})', { count: numberOfCasesReceived.value })
  }
  return t('All Patients ({filtered}/{received})', {
    filtered: numberOfCasesAfterFiltering.value,
    received: numberOfCasesReceived.value
  })
})

function onShowMore() {
  increaseItemsToShowByTen()
}

function filterCases(caze: CaseDTO): boolean {
  return [filterByHospital, filterByDepartment, filterByWard].every((filter) => filter(caze))
}

function filterByHospital(caze: CaseDTO): boolean {
  return (
    !patientFilter.value.hospitals.length ||
    patientFilter.value.hospitals.some((h: HospitalDTO) => h.id === caze.hospital.id)
  )
}

function filterByDepartment(caze: CaseDTO): boolean {
  return (
    !patientFilter.value.departments.length ||
    patientFilter.value.departments.includes(caze.department.name)
  )
}

function filterByWard(caze: CaseDTO): boolean {
  return !patientFilter.value.wards.length || patientFilter.value.wards.includes(caze.ward.name)
}
</script>
