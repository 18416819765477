<template>
  <THBanner v-if="isCallNotJoined" bg-color="primary-dark">
    <template #text>
      <div class="mb-4">
        {{ t('The following consultation is currently taking place. Please join.') }}
      </div>
      <div class="flex mb-2 items-center flex-wrap">
        <ConsultationTypeChip
          :consultation-type="consultationRequest.urgency"
          :use-colors="false"
          class="w-fit"
        />

        <span v-if="isEmergencyRequest" data-testid="emergency-call-label">
          {{ displayEmergencyCallLabel }} &nbsp;
        </span>
        <PatientTitle :patient="consultationRequest.case.patient" />
      </div>
      <div
        v-if="!isEmergencyRequest"
        data-testid="consultation-request-question"
        class="text-body-1"
      >
        {{ consultationRequest.question }}
      </div>
    </template>
    <template #actions>
      <VBtn
        v-if="showJoinCallButton"
        data-testid="join-call-button"
        variant="outlined"
        size="large"
        class="button-large"
        prepend-icon="videocam"
        :loading="joinCallInProgress"
        @click="joinCall"
      >
        {{ t('Accept') }}
      </VBtn>
    </template>
  </THBanner>
</template>

<script setup lang="ts">
import ConsultationTypeChip from '@/atoms/ConsultationTypeChip.vue'
import PatientTitle from '@/components/patient/components/atoms/PatientTitle.vue'
import { type ConsultationRequestDTO } from '@/generated'
import { useI18n } from 'vue-i18n'
import { useCallStore } from '@/stores/call'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import THBanner from '@/atoms/THBanner.vue'
import { useConsultationRequest } from '@/composables/useConsultationRequest'

const { t } = useI18n()

const props = defineProps<{ consultationRequest: ConsultationRequestDTO }>()

const { callConsultationRequestId, isInCall } = storeToRefs(useCallStore())
const { isEmergencyRequest, consultationId } = useConsultationRequest(props.consultationRequest)
const joinCallInProgress = ref<boolean>(false)

const isCallNotJoined = computed<boolean>(
  () => callConsultationRequestId.value !== consultationId.value
)

const displayEmergencyCallLabel = computed<string>(() =>
  isEmergencyRequest.value ? t('Emergency call') + ':' : ''
)

const showJoinCallButton = computed<boolean>(() => !isInCall.value)

async function joinCall(): Promise<void> {
  joinCallInProgress.value = true
  await useCallStore().joinCall(consultationId.value)
  joinCallInProgress.value = false
}
</script>
