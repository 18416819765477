<template>
  <VContainer class="pb-2">
    <VExpansionPanels flat bg-color="background" :model-value="[0]">
      <VExpansionPanel class="mt-3 mb-0 pt-0" :ripple="false">
        <VExpansionPanelTitle class="p-0 mb-6" static>
          <h2 data-testid="consultation-panel-heading" class="text-h2 max-w-[60%]">
            {{ displayHeading }}
          </h2>
          <template #actions="{ expanded }">
            <div class="button-small text-primary p-0">
              <span v-if="expanded" class="button-small">
                {{ t('Hide') }}
                <VIcon icon="expand_less" size="24" />
              </span>
              <span v-else class="button-small">
                {{ t('Show') }}
                <VIcon icon="expand_more" size="24" />
              </span>
            </div>
          </template>
        </VExpansionPanelTitle>
        <div v-if="hasChiefPhysicianShift || hasGodModeShift" class="mb-4 align-content-center">
          <ConsultationFilterButton class="mb-1" />
          <ConsultationFilterChips />
        </div>
        <VExpansionPanelText>
          <SentAndReceivedConsultationTabs
            :received-consultation-requests="filteredConsultationRequests"
            :sent-consultation-requests="outgoingConsultationRequests"
          />
        </VExpansionPanelText>
      </VExpansionPanel>
    </VExpansionPanels>
  </VContainer>
</template>

<script setup lang="ts">
import SentAndReceivedConsultationTabs from '@/components/consultationrequest/SentAndReceivedConsultationTabs.vue'
import type { ConsultationRequestDTO } from '@/generated'
import { useConsultationRequestStore } from '@/stores/consultationRequest'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import ConsultationFilterButton from './filter/ConsultationFilterButton.vue'
import ConsultationFilterChips from './filter/ConsultationFilterChips.vue'
const { t } = useI18n()

const { hasChiefPhysicianShift, hasGodModeShift } = storeToRefs(useUserStore())
const consultationRequestStore = useConsultationRequestStore()
const { incomingConsultationRequests, outgoingConsultationRequests, consultationFilter } =
  storeToRefs(consultationRequestStore)

const displayHeading = computed<string>(() => {
  return `${t('Consultation Requests')} (${incomingConsultationRequests.value.length + outgoingConsultationRequests.value.length})`
})

const filteredConsultationRequests = computed<ConsultationRequestDTO[]>(() => {
  return incomingConsultationRequests.value.filter(filterConsultationRequests)
})

function filterConsultationRequests(consultation: ConsultationRequestDTO): boolean {
  return (
    hasChiefPhysicianShift &&
    [
      filterByHospital,
      filterByMedicalField,
      filterByPatientDepartment,
      filterByUrgency,
      filterByAssignee,
      filterByRequester
    ].every((filter) => filter(consultation))
  )
}

function filterByHospital(consultation: ConsultationRequestDTO): boolean {
  return (
    !consultationFilter.value.hospitals.length ||
    consultationFilter.value.hospitals.some((h) => h.id === consultation.case.hospital.id)
  )
}

function filterByMedicalField(consultation: ConsultationRequestDTO): boolean {
  return (
    !consultationFilter.value.targetMedicalFields.length ||
    consultationFilter.value.targetMedicalFields.some((f) => f === consultation.medicalField)
  )
}

function filterByPatientDepartment(consultation: ConsultationRequestDTO): boolean {
  return (
    !consultationFilter.value.patientDepartments.length ||
    consultationFilter.value.patientDepartments.some(
      (d) => d.id === consultation.case.department.id
    )
  )
}

function filterByUrgency(consultation: ConsultationRequestDTO): boolean {
  return (
    !consultationFilter.value.urgencies.length ||
    consultationFilter.value.urgencies.some((u) => u === consultation.urgency)
  )
}

function filterByAssignee(consultation: ConsultationRequestDTO): boolean {
  return (
    !consultationFilter.value.assignees.length ||
    consultationFilter.value.assignees.some((a) => a.userName === consultation.assignedTo?.userName)
  )
}

function filterByRequester(consultation: ConsultationRequestDTO): boolean {
  return (
    !consultationFilter.value.requesters.length ||
    consultationFilter.value.requesters.some(
      (r) => r.userName === consultation.requestedBy.userName
    )
  )
}
</script>

<style scoped>
.v-expansion-panel-title :hover {
  background-color: transparent !important;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0 0 0;
}
</style>
