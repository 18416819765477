<template>
  <VContainer class="pt-0">
    <h2 class="text-primary-text text-h2 mb-4">
      {{ h2text }}
    </h2>

    <div class="flex flex-row gap-2 flex-wrap mb-4">
      <PatientFilterButton />
      <PatientFilterChips />
    </div>

    <div v-if="casesToShow.length === 0 && casesLoading" class="my-2">
      <VProgressLinear indeterminate />
    </div>

    <PatientCard v-for="item in casesToShow" :key="item.id" :caze="item" />

    <div v-if="!casesLoading" class="flex justify-center py-4">
      <span
        v-if="noCasesAfterFiltering"
        data-testid="no-cases-after-filtering-message"
        class="text-body-2 text-secondary-text"
      >
        {{ t("No results. Please ensure that you haven't applied too many filters.") }}
      </span>
      <span
        v-else-if="noMoreCasesAvailable"
        data-testid="all-patients-shown-message"
        class="text-body-2 text-secondary-text"
      >
        {{ t('All patients are shown') }}
      </span>
      <VBtn
        v-else
        data-testid="show-more-button"
        class="button-large px-3"
        size="large"
        variant="text"
        color="primary"
        @click="onShowMore()"
      >
        {{ t('Show more') }}
      </VBtn>
    </div>
  </VContainer>
</template>

<script setup lang="ts">
import { type CaseDTO, type HospitalDTO } from '@/generated'
import PatientCard from '@/components/patient/components/molecules/PatientCard.vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useCaseStore } from '@/stores/case'
import { usePatientListStore } from '@/stores/patientList'
import { storeToRefs } from 'pinia'
import { compareCases } from '@/util/compareCases'
import { useRepeatWhileMounted } from '@/composables/repeatWhileMounted'
import { getRefreshIntervalPatientList } from '@/global/environmentGetters'
import PatientFilterButton from '@/components/patient/filter/PatientFilterButton.vue'
import PatientFilterChips from '@/components/patient/filter/PatientFilterChips.vue'

const { t } = useI18n()

const patientListStore = usePatientListStore()
const { patientFilter, cases, casesLoading } = storeToRefs(useCaseStore())
const { numberOfItemsToShow } = storeToRefs(patientListStore)
const { increaseItemsToShowByTen } = patientListStore

useRepeatWhileMounted(useCaseStore().refreshCases, getRefreshIntervalPatientList())

const caseList = computed<CaseDTO[]>(() => sort(cases.value))

const numberOfCasesReceived = computed<number>(() => {
  return caseList.value.length
})

const numberOfCasesAfterFiltering = computed<number>(() => {
  return filteredCases.value.length
})

const filteredCases = computed<CaseDTO[]>(() => {
  return caseList.value.filter(filterCases)
})

const casesToShow = computed<CaseDTO[]>(() => {
  return filteredCases.value.slice(0, numberOfItemsToShow.value)
})

const noMoreCasesAvailable = computed<boolean>(() => {
  return filteredCases.value.length <= numberOfItemsToShow.value
})

const noCasesAfterFiltering = computed<boolean>(() => {
  return filteredCases.value.length === 0
})

const h2text = computed<string>(() => {
  if (numberOfCasesReceived.value === numberOfCasesAfterFiltering.value) {
    return t('Patients ({count})', { count: numberOfCasesReceived.value })
  }
  return t('Patients ({filtered}/{received})', {
    filtered: numberOfCasesAfterFiltering.value,
    received: numberOfCasesReceived.value
  })
})

function onShowMore(): void {
  increaseItemsToShowByTen()
}

function sort(cases: Record<string, CaseDTO>): CaseDTO[] {
  return Object.values(cases).sort(compareCases)
}

function filterCases(caze: CaseDTO): boolean {
  return [filterByHospital, filterByDepartment, filterByWard].every((filter) => filter(caze))
}

function filterByHospital(caze: CaseDTO): boolean {
  return (
    !patientFilter.value.hospitals.length ||
    patientFilter.value.hospitals.some((h: HospitalDTO) => h.id === caze.hospital.id)
  )
}

function filterByDepartment(caze: CaseDTO): boolean {
  return (
    !patientFilter.value.departments.length ||
    patientFilter.value.departments.includes(caze.department.name)
  )
}

function filterByWard(caze: CaseDTO): boolean {
  return !patientFilter.value.wards.length || patientFilter.value.wards.includes(caze.ward.name)
}
</script>
