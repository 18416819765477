<template>
  <VTabs
    v-model="currentTab"
    density="compact"
    color="primary"
    class="text-secondary-text border-b-thin mb-4"
  >
    <VTab data-testid="consultation-panel-tab-received" value="received" class="text-tab p-0 mr-2">
      {{ displayTabIncoming }}
    </VTab>
    <VTab data-testid="consultation-panel-tab-sent" value="sent" class="text-tab p-0 ml-2">
      {{ displayTabOutgoing }}
    </VTab>
  </VTabs>
  <VTabsWindow v-model="currentTab">
    <VTabsWindowItem value="received">
      <ConsultationRequestList
        data-testid="consultation-list-received"
        :consultation-requests="receivedConsultationRequests"
      />
    </VTabsWindowItem>
    <VTabsWindowItem value="sent">
      <ConsultationRequestList
        data-testid="consultation-list-sent"
        :consultation-requests="sentConsultationRequests"
      />
    </VTabsWindowItem>
  </VTabsWindow>
</template>

<script setup lang="ts">
import ConsultationRequestList from '@/components/consultationrequest/ConsultationRequestList.vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ConsultationRequestDTO } from '@/generated'

const { t } = useI18n()

const props = defineProps<{
  receivedConsultationRequests: ConsultationRequestDTO[]
  sentConsultationRequests: ConsultationRequestDTO[]
}>()

const currentTab = ref<'received' | 'sent'>('received')

const displayTabIncoming = computed<string>(() => {
  return `${t('Received')} (${props.receivedConsultationRequests.length})`
})
const displayTabOutgoing = computed<string>(() => {
  return `${t('Sent')} (${props.sentConsultationRequests.length})`
})
</script>

<style scoped>
.text-tab {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
</style>
