<template>
  <span>{{ displayTitle }}</span>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon'
import { type PatientDTO } from '@/generated'
import { computed } from 'vue'
import { translateGenderDTO } from '@/util/translations'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps<{
  patient: PatientDTO
}>()

const dateOfBirth = computed<DateTime>(() =>
  DateTime.fromFormat(props.patient.dateOfBirth, 'yyyy-MM-dd', {
    zone: 'utc'
  })
)

const age = computed<number>(() =>
  Math.trunc(DateTime.now().diff(dateOfBirth.value, 'years').years)
)
const displayTitle = computed<string>(
  () =>
    `${props.patient.lastName}, ${props.patient.firstName} (${translateGenderDTO(props.patient.gender, t)}, ${age.value})`
)
</script>
