<template>
  <PatientFilterChip
    v-for="hospital in patientFilter.hospitals"
    :key="hospital.id"
    :text="hospital.name"
    color="primary"
    :data-testid="`hospital-chip-${hospital.id}`"
    @close="removeHospitalFilter(hospital.id)"
  />
  <PatientFilterChip
    v-for="department in patientFilter.departments"
    :key="department"
    :text="department"
    color="secondary"
    :data-testid="`department-chip-${department}`"
    @close="removeDepartmentFilter(department)"
  />
  <PatientFilterChip
    v-for="ward in patientFilter.wards"
    :key="ward"
    :text="ward"
    color="secondary"
    :data-testid="`ward-chip-${ward}`"
    @close="removeWardFilter(ward)"
  />
</template>

<script setup lang="ts">
import { useCaseStore } from '@/stores/case'
import PatientFilterChip from '@/components/patient/filter/PatientFilterChip.vue'
import { storeToRefs } from 'pinia'

const { patientFilter } = storeToRefs(useCaseStore())
const { removeHospitalFilter, removeDepartmentFilter, removeWardFilter } = useCaseStore()
</script>
