<template>
  <VContainer class="flex items-center justify-center h-full">
    <VCard class="flex flex-col p-16 pb-10 max-width-600 gap-6">
      <VCardTitle class="p-0">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <h4 class="text-h4 mb-2">{{ useUserStore().getDisplayName }},</h4>
        <span class="text-body-1 text-secondary-text">{{ t('please select your shift.') }}</span>
      </VCardTitle>
      <ShiftSelection />
      <VDivider color="warning" />
      <span class="text-body-1 text-secondary-text">{{
        t(
          'Your selection is required for the correct assignment of consultation requests and emergency calls.'
        )
      }}</span>
    </VCard>
  </VContainer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ShiftSelection from '@/components/shift/ShiftSelection.vue'
import { useUserStore } from '@/stores/user'

const { t } = useI18n()
</script>

<style scoped>
.max-width-600 {
  max-width: 600px;
}
</style>
