<template>
  <VCard class="elevation-0 error-bg px-4 padding-y-13">
    <VCardText class="text-body-2 p-0 flex flex-row items-center">
      <VIcon icon="error_outline" color="error" class="mr-3" />
      <slot></slot>
    </VCardText>
  </VCard>
</template>

<style scoped>
.error-bg {
  background-color: #fdecea;
}

.padding-y-13 {
  padding-bottom: 13px;
  padding-top: 13px;
}
</style>
