import { defineStore } from 'pinia'
import { computed, type Ref, ref } from 'vue'
import type { Notice } from '@/stores/models/Notice'

export const useNoticeStore = defineStore('notice', () => {
  const queue: Ref<Notice[]> = ref([])

  const getCurrentNotice = computed(() => queue.value[0])

  function add(notice: Notice) {
    queue.value.push(notice)
  }

  function removeCurrentNotice() {
    queue.value.shift()
  }

  return { queue, getCurrentNotice, add, removeCurrentNotice }
})
