<template>
  <VDialog max-width="600px" :transition="false" attach="#app">
    <template #default="{ isActive }">
      <VCard class="p-4">
        <VCardTitle class="flex justify-space-between items-center p-0">
          <div class="text-h4 p-4">
            {{ t('Delete consultation') }}
          </div>
          <VBtn
            icon="close"
            variant="plain"
            class="button-small mr-2"
            :disabled="disableDeleteConsultationButton"
            size="small"
            :ripple="false"
            data-testid="btn-close"
            @click="isActive.value = false"
          />
        </VCardTitle>
        <VCardText class="text-body-1 p-4">
          {{ t('Do you really want to delete the consultation request?') }}
        </VCardText>
        <template #actions>
          <VCardActions>
            <VBtn
              variant="text"
              color="primary"
              data-testid="btn-cancel"
              :disabled="disableDeleteConsultationButton"
              class="ml-auto button-large"
              :text="t('Cancel')"
              @click="isActive.value = false"
            />
            <VBtn
              variant="elevated"
              color="primary"
              class="ml-4 button-large"
              :disabled="disableDeleteConsultationButton"
              :text="t('Delete consultation')"
              data-testid="btn-confirm"
              @click="confirmAndClose(isActive)"
            />
          </VCardActions>
        </template>
      </VCard>
    </template>
    <template #activator="{ props }">
      <VBtn
        v-bind="props"
        color="error"
        class="button-medium"
        variant="text"
        data-testid="btn-activator"
        :disabled="disableDeleteConsultationButton"
        @click.prevent
      >
        {{ t('Delete consultation') }}
      </VBtn>
    </template>
  </VDialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type Ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const { hasGodModeShift } = storeToRefs(useUserStore())

const disableDeleteConsultationButton = hasGodModeShift

const emit = defineEmits<{ (e: 'confirm'): void }>()

function confirmAndClose(isActiveRef: Ref<boolean>) {
  emit('confirm')
  isActiveRef.value = false
}
</script>
