import type { InjectionKey } from 'vue'

export const PostDeleteConsultationRequestActionKey: InjectionKey<() => Promise<void>> = Symbol(
  'postDeleteConsultationRequestAction'
)

export const PostUploadFileActionKey: InjectionKey<() => Promise<void>> =
  Symbol('postUploadFileAction')

export const PostDeleteFileActionKey: InjectionKey<() => Promise<void>> =
  Symbol('postDeleteFileAction')
