export const getKeycloakRealm = () => import.meta.env.VITE_KEYCLOAK_REALM ?? env.KEYCLOAK_REALM

export const getKeycloakUrl = () => import.meta.env.VITE_KEYCLOAK_URL ?? env.KEYCLOAK_URL

export const getSentryDsn = () => import.meta.env.VITE_SENTRY_DSN ?? env.SENTRY_DSN

export const getSentryEnvironment = () =>
  import.meta.env.VITE_SENTRY_ENVIRONMENT ?? env.SENTRY_ENVIRONMENT

export const getKeycloakClientId = () =>
  import.meta.env.VITE_KEYCLOAK_CLIENT_ID ?? env.KEYCLOAK_CLIENT_ID

export const getRefreshIntervalIncomingRequests = () =>
  secondsStringToMs(
    import.meta.env.VITE_REFRESH_INTERVAL_INCOMING_REQUESTS ??
      env.REFRESH_INTERVAL_INCOMING_REQUESTS ??
      10
  )

export const getRefreshIntervalOutgoingRequests = () =>
  secondsStringToMs(
    import.meta.env.VITE_REFRESH_INTERVAL_OUTGOING_REQUESTS ??
      env.REFRESH_INTERVAL_OUTGOING_REQUESTS ??
      60
  )

export const getRefreshIntervalPatientList = () =>
  secondsStringToMs(
    import.meta.env.VITE_REFRESH_INTERVAL_PATIENT_LIST ?? env.REFRESH_INTERVAL_PATIENT_LIST ?? 60
  )

export const getRefreshIntervalPatientDetails = () =>
  secondsStringToMs(
    import.meta.env.VITE_REFRESH_INTERVAL_PATIENT_DETAILS ??
      env.REFRESH_INTERVAL_PATIENT_DETAILS ??
      60
  )

export const getRefreshIntervalNotificationCenter = () =>
  secondsStringToMs(
    import.meta.env.VITE_REFRESH_INTERVAL_NOTIFICATION_CENTER ??
      env.REFRESH_INTERVAL_NOTIFICATION_CENTER ??
      10
  )

export const getRefreshIntervalVitalSigns = () =>
  secondsStringToMs(
    import.meta.env.VITE_REFRESH_INTERVAL_VITAL_SIGNS ?? env.REFRESH_INTERVAL_VITAL_SIGNS ?? 15
  )

export const getAkBaseUrl = () => import.meta.env.VITE_AK_BASE_URL ?? env.AK_BASE_URL

function secondsStringToMs(seconds: string) {
  return parseInt(seconds, 10) * 1000
}
