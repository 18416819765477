<template>
  <div class="flex justify-between items-center w-full max-sm:flex-col max-sm:items-start">
    <span class="text-body-2 text-secondary-text p-2">
      {{ t('© {currentYear} tcc clinical solutions GmbH', { currentYear }) }}
    </span>
    <div class="flex sm:gap-4 text-body-2 text-secondary-text flex-wrap">
      <VBtn
        :text="t('Support')"
        :href="t(`{'mailto:app-support@tcc-clinicalsolutions.de'}`)"
        variant="plain"
        :ripple="false"
        target="_blank"
        class="text-body-2 p-2"
      />
      <VBtn
        :text="t('Legal Notice')"
        :href="t('https://tcc-clinicalsolutions.de/en/imprint/')"
        variant="plain"
        :ripple="false"
        target="_blank"
        class="text-body-2 p-2"
      />
      <VBtn
        :text="t('Privacy Policy')"
        :href="t('https://tcc-clinicalsolutions.de/en/elmo-privacy-policy')"
        variant="plain"
        :ripple="false"
        target="_blank"
        class="text-body-2 p-2"
      />
      <VBtn
        :text="t('Terms of Use')"
        :href="t('https://tcc-clinicalsolutions.de/en/elmo-terms-and-conditions')"
        variant="plain"
        :ripple="false"
        target="_blank"
        class="text-body-2 p-2"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const currentYear = new Date().getFullYear()

const { t } = useI18n()
</script>
<style scoped>
.v-btn:not(:hover) {
  /* disable default fade-out behavior of plain variant VBtn */
  opacity: unset;
}
</style>
