import type { GetNotificationsResponseDTO } from '@/generated'
import { NotificationApi } from '@/generated'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'

export class NotificationService {
  static readonly notificationApi = new NotificationApi(globalApiConfig)

  static async getNotifications(): Promise<GetNotificationsResponseDTO> {
    return (await NotificationService.notificationApi.getNotifications()).data
  }

  static async markNotificationsStatusAsRead(lastReadNotificationId: string): Promise<void> {
    return (
      await NotificationService.notificationApi.markNotificationsStatusAsRead(
        lastReadNotificationId
      )
    ).data
  }
}
