<template>
  <svg width="20" height="20" viewBox="0 -2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="filled/navigation/close">
      <path
        id="Vector"
        d="M15.8334 5.34169L14.6584 4.16669L10.0001 8.82502L5.34175 4.16669L4.16675 5.34169L8.82508 10L4.16675 14.6584L5.34175 15.8334L10.0001 11.175L14.6584 15.8334L15.8334 14.6584L11.1751 10L15.8334 5.34169Z"
        fill="#98A2B3"
      />
    </g>
  </svg>
</template>
