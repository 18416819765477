import { onMounted, onUnmounted } from 'vue'

export function useRepeatWhileMounted(callback: () => void | Promise<void>, intervalMs: number) {
  let intervalId: ReturnType<typeof setInterval>

  async function refresh(): Promise<void> {
    await callback()
  }

  onMounted(async () => {
    intervalId = setInterval(refresh, intervalMs)
    await refresh()
  })

  onUnmounted(() => {
    clearInterval(intervalId)
  })
}
