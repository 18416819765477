<template>
  <THBanner bg-color="warning-background" class="p-2">
    <template #text>
      <VRow class="text-warning-dark m-0">
        <span class="flex">
          <ConsultationTypeChip
            :consultation-type="consultationRequest.urgency"
            use-colors
            dark
            class="align-self-center"
          />
          <ConsultationCountdown
            v-if="
              consultationRequest.nextEscalationAt &&
              isUrgencyWithCountdown(consultationRequest.urgency)
            "
            class="mr-1"
            dark
            regular
            :show-icon="false"
            :next-escalation-at="consultationRequest.nextEscalationAt"
            :urgency="consultationRequest.urgency"
          />
        </span>
        <VCol data-testid="consultation-desc" class="text-truncate">
          {{ [consultationRequest.case.department.name, consultationRequest.question].join(': ') }}
        </VCol>
      </VRow>
    </template>
    <template #actions>
      <VBtn
        v-if="userCanDecline"
        :disabled="hasGodModeShift"
        data-testid="decline-btn"
        variant="text"
        color="warning-dark"
        size="large"
        class="button-large"
        @click.prevent="declineConsultationRequest"
      >
        {{ t('Decline') }}
      </VBtn>
      <VBtn
        v-if="userCanAccept(hasChiefPhysicianShift)"
        :disabled="hasGodModeShift"
        data-testid="accept-btn"
        variant="text"
        color="warning-dark"
        size="large"
        class="button-large"
        @click.prevent="acceptConsultationRequest"
      >
        {{ t('I will call back') }}
      </VBtn>
      <StartCallButton
        v-if="userCanStartCall"
        :disabled="hasGodModeShift"
        :consultation-request-id="consultationId"
        :variant="ButtonVariant.ANSWER"
        size="large"
        class="button-large elevation-0"
        color="warning-dark"
        bg-color="warning-dark"
        :loading="startCallInProgress"
        @click="startCall"
      />
    </template>
  </THBanner>
</template>

<script setup lang="ts">
import ConsultationTypeChip from '@/atoms/ConsultationTypeChip.vue'
import { type ConsultationRequestDTO } from '@/generated'
import { ButtonVariant } from './ButtonVariant'
import { useI18n } from 'vue-i18n'
import { useCallStore } from '@/stores/call'
import { ref } from 'vue'
import { useConsultationRequest } from '@/composables/useConsultationRequest'
import ConsultationCountdown from '@/components/consultationrequest/ConsultationCountdown.vue'
import { isUrgencyWithCountdown } from '@/util/UrgencyWithCountdown'
import THBanner from '@/atoms/THBanner.vue'
import StartCallButton from '@/components/consultationrequest/StartCallButton.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const { hasGodModeShift, hasChiefPhysicianShift } = storeToRefs(useUserStore())
const props = defineProps<{ consultationRequest: ConsultationRequestDTO }>()
const startCallInProgress = ref<boolean>(false)

const {
  consultationId,
  userCanDecline,
  userCanStartCall,
  userCanAccept,
  acceptConsultationRequest,
  declineConsultationRequest
} = useConsultationRequest(props.consultationRequest)

async function startCall(): Promise<void> {
  startCallInProgress.value = true
  await useCallStore().startCall(props.consultationRequest.id)
  startCallInProgress.value = false
}
</script>
