<template>
  <VMenu rounded>
    <template #activator="{ props }">
      <VBtn icon v-bind="props" rounded="false" class="button-medium bg-grey-200">
        <UserAvatar />
      </VBtn>
    </template>
    <VCard class="pt-2 pb-2 mt-1 elevation-1" width="240">
      <VBtn
        data-testid="user-menu-settings-button"
        :to="{ name: routeNames.USER_PROFILE }"
        variant="text"
        :active="false"
        class="text-body-1 w-full justify-start"
      >
        <template #prepend>
          <VIcon icon="person" class="mr-7 opacity-60" />
        </template>
        {{ t('Account') }}
      </VBtn>
      <VBtn
        data-testid="user-menu-logout-button"
        :to="{ name: routeNames.LOGOUT }"
        variant="text"
        :active="false"
        class="button-medium text-body-1 w-full justify-start"
      >
        <template #prepend>
          <VIcon icon="power_settings_new" class="mr-7 opacity-60" />
        </template>
        {{ t('Logout') }}
      </VBtn>
    </VCard>
  </VMenu>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import UserAvatar from '@/components/navigation/UserAvatar.vue'
import { routeNames } from '@/router/routes'

const { t } = useI18n()
</script>
