<template>
  <THBanner bg-color="primary-dark">
    <template #text>
      <VIcon icon="error_outline" />
      {{ t('You do not have a shift selected and receive no consultation requests.') }}
    </template>
    <template #actions>
      <VBtn variant="outlined" class="button-medium" size="medium" @click="routeToUserSettings">{{
        t('Select shift')
      }}</VBtn>
    </template>
  </THBanner>
</template>

<script setup lang="ts">
import THBanner from '@/atoms/THBanner.vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { routeNames } from '@/router/routes'

const { t } = useI18n()
const { push } = useRouter()

function routeToUserSettings() {
  push(routeNames.USER_PROFILE)
}
</script>
