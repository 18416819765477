import { UrgencyDTO } from '@/generated'

export type UrgencyWithCountdown =
  | typeof UrgencyDTO.EMERGENCY
  | typeof UrgencyDTO.URGENT
  | typeof UrgencyDTO.ROUTINE

export function isUrgencyWithCountdown(urgency: UrgencyDTO): urgency is UrgencyWithCountdown {
  return (
    urgency === UrgencyDTO.EMERGENCY ||
    urgency === UrgencyDTO.URGENT ||
    urgency === UrgencyDTO.ROUTINE
  )
}
