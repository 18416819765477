<template>
  <div class="pl-2">
    <span class="text-subtitle-1">{{ title }}</span>
    <VRow v-for="item in items" :key="item.name" no-gutters>
      <VCol class="flex flex-row items-center">
        <VCheckbox
          v-model="selectedOptions"
          :value="item.name"
          :label="`${item.name} (${item.count})`"
          :ripple="false"
          :disabled="item.count === 0"
          density="comfortable"
          hide-details
        />
      </VCol>
    </VRow>
  </div>
</template>

<script setup lang="ts">
import type { FilterOption } from '@/components/patient/models/FilterOption'

const selectedOptions = defineModel<string[]>()

defineProps<{
  title: string
  items: FilterOption[]
}>()
</script>
