<template>
  <VChip closable close-icon="cancel" variant="flat" @click:close="emit('close')">
    {{ text }}
  </VChip>
</template>

<script setup lang="ts">
defineProps<{
  text: string
}>()

const emit = defineEmits(['close'])
</script>
