/* c8 ignore file */
import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/router/routes'
import { globalRouterGuards } from '@/router/routerguards'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

router.beforeEach(globalRouterGuards)

export default router
