export enum VitalSignsKeys {
  NON_INV_S_BP = 'NON-INV-S-BP',
  TEMP = 'TEMP',
  SPO2 = 'SPO2',
  INV_S_BP = 'INV-S-BP',
  INV_M_BP = 'INV-M-BP',
  NON_INV_D_BP = 'NON-INV-D-BP',
  RR = 'RR',
  HEART_RATE = 'HEART_RATE',
  INV_D_BP = 'INV-D-BP',
  NON_INV_M_BP = 'NON-INV-M-BP',
  PULSE_RATE = 'PULSE_RATE',
  PATIENT_NAME = 'PATIENT_NAME'
}
