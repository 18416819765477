<template>
  <VDialog
    v-model="showDialog"
    activator="parent"
    max-width="600px"
    :transition="false"
    attach="#app"
  >
    <template #default>
      <VCard class="p-4">
        <ConsultationFilterContent @close-dialog="closeDialog" />
        <template #actions>
          <VCardActions>
            <div class="pt-2">
              <VBtn
                variant="text"
                color="primary"
                data-testid="btn-cancel"
                class="ml-auto button-large"
                size="large"
                :text="t('Close')"
                @click="closeDialog"
              />
              <VBtn
                variant="flat"
                color="primary"
                class="ml-4 button-large"
                size="large"
                :text="t('Apply')"
                data-testid="btn-confirm"
                @click="onSubmit"
              />
            </div>
          </VCardActions>
        </template>
      </VCard>
    </template>
  </VDialog>
</template>

<script setup lang="ts">
import { useConsultationRequestStore } from '@/stores/consultationRequest'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ConsultationFilterContent from './ConsultationFilterContent.vue'

const { t } = useI18n()
const showDialog = ref<boolean>(false)

function onSubmit() {
  useConsultationRequestStore().commitConsultationRequestFilterInput()
  closeDialog()
}

function closeDialog() {
  showDialog.value = false
}
</script>
