<template>
  <div class="flex flex-col">
    <IncognitoBanner v-if="isIncognito" />
    <template v-if="!hasGodModeShift">
      <CallInProgressBanner
        v-for="item in consultationRequestsWithActiveCall"
        :key="item.id"
        :consultation-request="item"
      />
      <ConsultationBanner
        v-for="item in consultationRequestsWithConsultationBanner"
        :key="item.id"
        :consultation-request="item"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import CallInProgressBanner from '@/components/consultationrequest/CallInProgressBanner.vue'
import { useConsultationRequestStore } from '@/stores/consultationRequest'
import { storeToRefs } from 'pinia'
import { useRepeatWhileMounted } from '@/composables/repeatWhileMounted'
import {
  getRefreshIntervalIncomingRequests,
  getRefreshIntervalOutgoingRequests
} from '@/global/environmentGetters'
import { compareConsultationRequests } from '@/util/compareConsultationRequests'
import { computed } from 'vue'
import { type ConsultationRequestDTO, ConsultationRequestStatusDTO, UrgencyDTO } from '@/generated'
import ConsultationBanner from '@/components/consultationrequest/ConsultationBanner.vue'
import IncognitoBanner from '@/components/shift/IncognitoBanner.vue'
import { useUserStore } from '@/stores/user'

useRepeatWhileMounted(
  useConsultationRequestStore().refreshIncomingConsultationRequestList,
  getRefreshIntervalIncomingRequests()
)
useRepeatWhileMounted(
  useConsultationRequestStore().refreshOutgoingConsultationRequestList,
  getRefreshIntervalOutgoingRequests()
)

const { getConsultationRequestsWithActiveCall, incomingConsultationRequests } = storeToRefs(
  useConsultationRequestStore()
)

const { isIncognito, hasGodModeShift } = storeToRefs(useUserStore())

const consultationRequestsWithConsultationBanner = computed<ConsultationRequestDTO[]>(() => {
  return incomingConsultationRequests.value
    .filter(
      (cr) => cr.status === ConsultationRequestStatusDTO.CREATED && cr.urgency === UrgencyDTO.URGENT
    )
    .sort(compareConsultationRequests)
})

// TODO: different sorting for consultation requests in progress? (not by nextEscalationAt in the second instance?)

const consultationRequestsWithActiveCall = computed<ConsultationRequestDTO[]>(() =>
  [...getConsultationRequestsWithActiveCall.value].sort(compareConsultationRequests)
)
</script>

<style scoped>
.flex {
  gap: 1px;
}
</style>
