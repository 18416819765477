import { h } from 'vue'
import type { IconSet, IconProps } from 'vuetify'
import radioCheckedIcon from '@/atoms/RadioCheckedIcon.vue'
import clear20 from '@/atoms/Clear20Icon.vue'

const customIconsToComponent: any = {
  radioCheckedIcon,
  clear20
}

const customIcons: IconSet = {
  component: (props: IconProps) => h(customIconsToComponent[props.icon as string])
}

export { customIcons }
